import { EyeIcon, ImageUpIcon } from 'lucide-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { ProductSchema } from '@/schemas/product';
import type { DialogType } from '@/store/dialog/dialog.slice';
import { selectDialog } from '@/store/dialog/dialog.slice';
import { selectProduct } from '@/store/products/products.slice';

import type { Row } from '@tanstack/react-table';

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
}

export function DataTableRowActions<TData>({
  row,
}: DataTableRowActionsProps<TData>) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const product = ProductSchema.parse(row.original);

  const openDialogHandler = useCallback(
    (dialog: DialogType) => {
      dispatch(selectProduct(product));
      dispatch(selectDialog(dialog));
    },
    [dispatch, product],
  );

  return (
    <div className="flex gap-2">
      <Button
        aria-label="Upload Image"
        className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        onClick={() => {
          openDialogHandler('upload-image');
        }}
        title="Upload Image"
        variant="ghost"
      >
        <ImageUpIcon className="h-4 w-4" />
        <span className="sr-only">Edit Product</span>
      </Button>
      <Button
        aria-label="View Batches"
        className="flex h-8 w-8 p-0"
        onClick={() => {
          navigate(`${row.original.id}/batches`);
        }}
        title="View Batches"
        variant="ghost"
      >
        <EyeIcon className="h-4 w-4" />
        <span className="sr-only">Edit Product</span>
      </Button>
    </div>
  );
}
