import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { TagForm } from '@/components/forms/tag-form';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useBatchesNestedQuery } from '@/hooks/use-batches-nested-query';
import { useCreateTagMutation } from '@/hooks/use-create-tag-mutation';
import { useProductsQuery } from '@/hooks/use-products-query';
import {
  createTagFormSchema,
  type CreateTagFormData,
} from '@/pages/dashboard/tags';

export function CreateTagDialog() {
  const { productId, batchId } = useParams() as {
    productId: string;
    batchId: string;
  };

  const form = useForm<CreateTagFormData>({
    resolver: zodResolver(createTagFormSchema),
    defaultValues: {
      batchId,
      productId,
      uid: '123',
      sampleInfo: {
        destination: '',
        purpose: '',
        testingCriteria: '',
      },
    },
  });

  const createTagMutation = useCreateTagMutation();
  const productsQuery = useProductsQuery();
  const batchesQuery = useBatchesNestedQuery({ productId });
  const [open, setOpen] = useState(false);

  const createTagDialogHandler = () => {
    setOpen(true);
  };
  const createTagHandler = useCallback(
    (tag: CreateTagFormData) => {
      console.log(tag);
      // createTagMutation.mutate(tag);
      setOpen(false);
    },
    [createTagMutation],
  );

  const closeDialogHandler = useCallback(() => {
    setOpen(false);
  }, []);
  return (
    <>
      <Dialog onOpenChange={setOpen} open={open}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create new tag</DialogTitle>
            <DialogDescription>
              This action will create a new tag
            </DialogDescription>
          </DialogHeader>
          <TagForm
            // batches={batchesQuery.data?.batches ?? []}
            form={form}
            onCancel={closeDialogHandler}
            onSubmit={createTagHandler}
            // products={productsQuery.data?.products ?? []}
          />
        </DialogContent>
      </Dialog>
      <Button onClick={createTagDialogHandler}>Create new tag</Button>
    </>
  );
}
