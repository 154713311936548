import { NavLink } from 'react-router-dom';

import { cn } from '@/lib/utils';

interface MainNavProps extends React.HTMLAttributes<HTMLElement> {
  items: {
    href: string;
    title: string;
  }[];
}

export function MainNav({ className, items, ...props }: MainNavProps) {
  return (
    <nav
      className={cn('flex items-center space-x-4 lg:space-x-6', className)}
      {...props}
    >
      {items.map((item) => (
        <NavLink
          className={({ isActive }) =>
            cn(
              !isActive ? 'text-muted-foreground' : '',
              'text-sm font-medium transition-colors hover:text-primary',
            )
          }
          key={item.href}
          to={item.href}
        >
          {item.title}
        </NavLink>
      ))}
    </nav>
  );
}
