import { useQuery } from '@tanstack/react-query';
import { usePasswordless } from 'amazon-cognito-passwordless-auth/react';

import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import { CompanySchema } from '@/schemas/company';

const CompanyApiResponseSchema = createApiResponseSchema(CompanySchema);

async function getCompany(companyId: string) {
  const response = await api.get(apiPaths.COMPANY_BY_ID({ companyId }));
  const { data } = CompanyApiResponseSchema.parse(response.data);
  return { company: data };
}

export function useCompany() {
  const { tokensParsed } = usePasswordless();
  const companyId = tokensParsed?.idToken['custom:tenant_id'] as string;

  return useQuery({
    queryKey: ['company', companyId],
    queryFn: () => getCompany(companyId),
    enabled: Boolean(companyId),
  });
}
