import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';

import { useAppSelector } from './use-app-selector';

const BrandListApiResponseSchema = createApiResponseSchema(
  z.array(
    z.object({
      roll: z.string(),
      count: z.number(),
    }),
  ),
);

async function getAvailableRolls({ brandId }: { brandId: string }) {
  const response = await api.get(apiPaths.AVAILABLE_ROLLS({ brandId }));
  const { data } = BrandListApiResponseSchema.parse(response.data);
  return { availableRolls: data };
}

export function useAvailableRolls() {
  const selectedBrand = useAppSelector((state) => state.brands.selected);
  return useQuery({
    queryKey: ['getAvailableRolls', selectedBrand?.id],
    queryFn: () => getAvailableRolls({ brandId: selectedBrand?.id ?? '' }),
    enabled: Boolean(selectedBrand?.id),
  });
}
