import { useQuery } from '@tanstack/react-query';

import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import { IndicatorsSchema } from '@/schemas/indicators-schema';

import { useAppSelector } from './use-app-selector';

const BrandListApiResponseSchema = createApiResponseSchema(IndicatorsSchema);

interface GetIndicatorsParams {
  brandId?: string;
  tidId?: string;
}

async function getIndicators({ tidId, brandId }: GetIndicatorsParams) {
  const response = await api.get(
    apiPaths.INDICATORS({ brandId: brandId ?? '' }),
    {
      params: { tidId },
    },
  );

  const { data } = BrandListApiResponseSchema.parse(response.data);

  return {
    indicators: data,
  };
}

export function useIndicatorsQuery({ tidId }: GetIndicatorsParams) {
  const selectedBrand = useAppSelector((state) => state.brands.selected);

  return useQuery({
    queryKey: ['indicators', selectedBrand?.id, tidId],
    queryFn: () =>
      getIndicators({
        brandId: selectedBrand?.id ?? '',
        tidId,
      }),
    enabled: Boolean(selectedBrand),
  });
}
