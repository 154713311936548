import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import type { BatchFormData } from '@/components/forms/batch-form';
import { toast } from '@/components/ui/use-toast';
import { apiPaths } from '@/constants/api-paths';
import { formatDate } from '@/helpers/format-date';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import type { Batch } from '@/schemas/batch';

import { useAppSelector } from './use-app-selector';

const BatchSchema = z.object({
  id: z.string().uuid(),
  lot: z.string(),
  expiresAt: z.string().transform(formatDate).nullable(),
  producedAt: z.string().transform(formatDate).nullable(),
  tags: z.array(z.string()).optional(),
  brandId: z.string().uuid(),
  productId: z.string().uuid(),
  createdAt: z.string().transform(formatDate),
  updatedAt: z.string().transform(formatDate),

  // Optional fields, as they are missing in the response
  product: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      brandId: z.string().uuid(),
      sku: z.string(),
      image: z.string().optional(),
      templateId: z.string().uuid().nullable().optional(),
      createdAt: z.string().transform(formatDate),
      updatedAt: z.string().transform(formatDate),
    })
    .nullable()
    .optional(),
  batchMetafields: z
    .array(
      z.object({
        id: z.string().uuid(),
        value: z.string(),
        metafield: z.object({
          id: z.string().uuid(),
          key: z.string(),
          type: z.string(),
          listOptions: z.array(z.string()).nullable().optional(),
          isFavorite: z.boolean(),
          createdAt: z.string().transform(formatDate),
          updatedAt: z.string().transform(formatDate),
        }),
        createdAt: z.string().transform(formatDate),
        updatedAt: z.string().transform(formatDate),
      }),
    )
    .nullable()
    .optional(),
  tidCount: z.number().int().optional(),
});

interface CreateBatchParams extends BatchFormData {
  brandId: string;
}

const BatcApiResponseSchema = createApiResponseSchema(BatchSchema);

async function createBatch({ brandId, productId, ...data }: CreateBatchParams) {
  const response = await api.post<Batch>(
    apiPaths.BATCHES_NESTED({ brandId, productId }),
    data,
  );

  const batch = BatcApiResponseSchema.parse(response.data);

  return { batch };
}

export function useCreateBatchMutation() {
  const selectedBrand = useAppSelector((state) => state.brands.selected);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: BatchFormData) =>
      createBatch({
        brandId: selectedBrand?.id ?? '',
        ...data,
        producedAt: data.producedAt
          ? new Date(data.producedAt).toISOString() // Converts to ISO 8601
          : undefined,
        metafields: data.metafields?.filter(
          (metafield) => metafield.metafieldId && metafield.value,
        ),
      }),
    mutationKey: ['create-batch'],
    onSuccess: () => {
      toast({
        title: 'Batch created successfully',
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['batches'],
      });
    },
    onError: (error) => {
      toast({
        title: 'Error creating batch',
        description: error.message,
        variant: 'destructive',
      });
    },
  });
}
