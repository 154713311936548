import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { ShortenedUUID } from '@/components/shortened-uuid';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { getMetafields } from '@/helpers/get-metafields';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useBatchQuery } from '@/hooks/use-batch-query';
import { useProductQuery } from '@/hooks/use-product-query';
import { useSearchFilters } from '@/hooks/use-search-filters';
import { useTidsNestedQuery } from '@/hooks/use-tids-query';

import { tidColumns } from './components/data-table/tid-columns';
import { DataTable } from './components/data-table/tid-table';

import type {
  ColumnFiltersState,
  PaginationState,
} from '@tanstack/react-table';

export function Batch() {
  const { batchId, productId } = useParams() as {
    batchId: string;
    productId: string;
  };

  const brand = useAppSelector((state) => state.brands.selected);
  const productQuery = useProductQuery({ productId, brandId: brand?.id ?? '' });
  const filters = useSearchFilters();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const batchQuery = useBatchQuery({ batchId, productId });

  const tidsQuery = useTidsNestedQuery({ batchId, productId });

  useEffect(() => {
    setColumnFilters(filters);
  }, [filters]);

  const metafields = getMetafields(batchQuery.data?.batch.batchMetafields);

  return (
    <div className="flex-1 space-y-4 p-8 pt-6">
      <div className="flex items-center justify-between space-y-2">
        <div className="flex gap-1">
          <Link className="text-primary hover:underline" to="../products">
            <h2 className="text-2xl font-medium tracking-tight">Products</h2>
          </Link>
          <h2 className="text-2xl font-medium tracking-tight">/</h2>
          <h2 className="text-2xl font-medium tracking-tight">
            {productQuery.data?.product.sku}
          </h2>
          <h2 className="text-2xl font-medium tracking-tight">/</h2>
          <Link
            className="text-primary hover:underline"
            to={`../products/${productId}/batches`}
          >
            <h2 className="text-2xl font-medium tracking-tight">Batches</h2>
          </Link>
          <h2 className="text-2xl font-medium tracking-tight">/</h2>
          <h2 className="text-2xl font-bold tracking-tight">
            {batchQuery.data?.batch.lot}
          </h2>
        </div>
      </div>
      <Card>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Created At</TableHead>
              <TableHead>Product Name</TableHead>
              <TableHead>SKU</TableHead>
              <TableHead className="text-right">Count (Tags)</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="font-medium">
                {batchQuery.data?.batch.createdAt}
              </TableCell>
              <TableCell>{batchQuery.data?.batch.product?.name}</TableCell>
              <TableCell>{batchQuery.data?.batch.product?.sku}</TableCell>
              <TableCell className="text-right">
                {batchQuery.data?.batch.tidCount}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      <div className="grid gap-4 lg:grid-cols-6">
        <Card className="col-span-2 flex flex-col gap-4">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-base font-medium w-full flex gap-4 justify-between items-center">
              <span className="text-muted-foreground">Batch Id:</span>
              <ShortenedUUID
                copyHidden
                uuid={batchQuery.data?.batch.id ?? ''}
              />
            </CardTitle>
          </CardHeader>
          <CardContent className="w-full flex flex-col gap-4">
            <p className="w-full flex gap-4 justify-between">
              <span className="text-muted-foreground">Lot:</span>
              {batchQuery.data?.batch.lot ?? ''}
            </p>
            {batchQuery.data?.batch.producedAt ? (
              <p className="w-full flex gap-4 justify-between">
                <span className="text-muted-foreground">Produced at:</span>
                {batchQuery.data.batch.producedAt}
              </p>
            ) : null}
            {batchQuery.data?.batch.expiresAt ? (
              <p className="w-full flex gap-4 justify-between">
                <span className="text-muted-foreground">Expires at:</span>
                {batchQuery.data.batch.expiresAt}
              </p>
            ) : null}
            {metafields.map((field) =>
              field.value ? (
                <p
                  className="w-full flex gap-4 justify-between"
                  key={field.key}
                >
                  <span className="text-muted-foreground">{field.key}:</span>
                  {field.value}
                </p>
              ) : null,
            )}
            {batchQuery.data?.batch.tags?.length ? (
              <div className="flex flex-col gap-2">
                <p className="w-full flex gap-4 justify-between">
                  <span className="text-muted-foreground">Tags:</span>
                </p>
                <div className="flex flex-wrap gap-1">
                  {batchQuery.data.batch.tags.map((tag) => (
                    <Badge key={tag} variant="secondary">
                      {tag}
                    </Badge>
                  ))}
                </div>
              </div>
            ) : null}
          </CardContent>
        </Card>
        <div className="col-span-4">
          <DataTable
            columnFilters={columnFilters}
            columns={tidColumns}
            data={tidsQuery.data?.tags ?? []}
            onColumnFiltersChange={setColumnFilters}
            onPaginationChange={setPagination}
            pagination={pagination}
            rowCount={tidsQuery.data?.pagination?.total}
            showToolbar={false}
          />
        </div>
      </div>
    </div>
  );
}
