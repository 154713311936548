import { createSlice } from '@reduxjs/toolkit';

import type { Product } from '@/schemas/product';

import type { PayloadAction } from '@reduxjs/toolkit';

export interface ProductsState {
  selected?: Product;
}

const initialState: ProductsState = {
  selected: undefined,
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    selectProduct: (state, action: PayloadAction<Product | undefined>) => {
      state.selected = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { selectProduct } = productsSlice.actions;

export default productsSlice.reducer;
