import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import type { Product } from '@/schemas/product';

import { Button } from '../ui/button';

const createProductFormSchema = z.object({
  name: z
    .string({ required_error: 'Product name is required' })
    .min(2, {
      message: 'Name must be at least 2 characters.',
    })
    .max(30, {
      message: 'Name must not be longer than 30 characters.',
    }),
  sku: z.string({
    required_error: 'Product SKU is required.',
  }),
  image: z.string().optional(),
  brandId: z.string().optional(),
  productId: z.string().optional(),
});

export type CreateProductFormData = z.infer<typeof createProductFormSchema>;

export function ProductForm({
  onCancel,
  onSubmit,
  product,
}: {
  onCancel: () => void;
  onSubmit: (product: CreateProductFormData) => Promise<void>;
  product?: Product;
}) {
  const form = useForm<CreateProductFormData>({
    resolver: zodResolver(createProductFormSchema),
    values: {
      name: product?.name ?? '',
      sku: product?.sku ?? '',
    },
  });

  async function submitHandler(data: CreateProductFormData) {
    await onSubmit(data);
  }

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(submitHandler)}>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input placeholder="Product name" {...field} />
                </FormControl>
                <FormDescription>
                  This is the name that will be displayed on the product
                  authentication page.
                </FormDescription>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="sku"
          render={({ field }) => (
            <FormItem>
              <FormLabel>SKU</FormLabel>
              <FormControl>
                <Input placeholder="XYZ-12345" {...field} />
              </FormControl>
              <FormDescription>
                This is the SKU of your product.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
          <Button onClick={onCancel} variant="outline">
            Cancel
          </Button>
          <Button type="submit">Continue</Button>
        </div>
      </form>
    </Form>
  );
}
