import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { ButtonLoading } from '@/components/button-loading';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

const createOrderFormSchema = z.object({
  code: z
    .string({
      required_error: 'Code is required.',
    })
    .regex(/^[a-zA-Z0-9-_]+$/, {
      message:
        'Order code must only contain letters, numbers, dashes, and underscores, without spaces.',
    }),
  chipModel: z.string({
    required_error: 'Chip model is required.',
  }),
});

export type CreateOrderFormData = z.infer<typeof createOrderFormSchema>;

export function OrderForm({
  onCancel,
  onSubmit,
}: {
  onCancel: () => void;
  onSubmit: (order: CreateOrderFormData) => void;
}) {
  const form = useForm<CreateOrderFormData>({
    resolver: zodResolver(createOrderFormSchema),
  });

  const { isDirty, isValid, isSubmitting } = form.formState;

  const submitHandler = (order: CreateOrderFormData) => {
    onSubmit(order);
  };

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(submitHandler)}>
        <FormField
          control={form.control}
          name="code"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Code</FormLabel>
              <FormControl>
                <Input placeholder="LOT-0021" {...field} />
              </FormControl>
              <FormDescription>
                This is the Order identifier, to be used by the product tags
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="chipModel"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Chip Model</FormLabel>
              <FormControl>
                <Input placeholder="NXP 213" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
          <Button onClick={onCancel} variant="outline">
            Cancel
          </Button>

          {isSubmitting ? (
            <ButtonLoading />
          ) : (
            <Button disabled={!(isDirty && isValid)} type="submit">
              Continue
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
}
