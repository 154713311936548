import { z } from 'zod';

import { formatDate } from '@/helpers/format-date';

export const CompanySchema = z.object({
  id: z.string(),
  companyCrmId: z.string(),
  name: z.string(),
  logo: z.string().nullable(),
  logoUrl: z.string().nullable(),
  createdAt: z.string().datetime().transform(formatDate),
  updatedAt: z.string().datetime().transform(formatDate),
});

export type Company = z.infer<typeof CompanySchema>;
