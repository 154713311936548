// import { z } from 'zod';

// import { alertColumns } from '@/components/alert-columns';
// import { DataTable as AlertDataTable } from '@/components/alert-table';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
// import { alertSchema } from '@/data/alert-schema';
// import alertData from '@/data/alerts.json';

import { Charts } from './charts';

// function getAlerts() {
//   return z.array(alertSchema).parse(alertData);
// }

export function Analytics() {
  // const alerts = getAlerts();

  const tabs = [
    {
      value: 'map',
      label: 'Map',
      content: (
        <iframe
          height={640}
          src="https://analytics.zoho.com/open-view/2185350000007001026/46673cea0fc0904d6db0a1d345aaba5e"
          title="scan-map"
          width="100%"
        />
      ),
      disabled: false,
    },
    {
      value: 'analytics',
      label: 'Analytics',
      content: <Charts />,
      disabled: false,
    },
    {
      value: 'alerts',
      label: 'Alerts',

      content: (
        <div>
          <iframe
            height={320}
            src="https://analytics.zoho.com/open-view/2185350000007294485"
            title="alert-counterfeit"
            width="100%"
          />
          <iframe
            height={320}
            src="https://analytics.zoho.com/open-view/2185350000007294148"
            title="alert-consumer"
            width="100%"
          />
        </div>
      ),
      disabled: false,
    },
  ];

  return (
    <Tabs className="space-y-4" defaultValue="map">
      <TabsList>
        {tabs.map((tab) => (
          <TabsTrigger
            disabled={tab.disabled}
            key={tab.value}
            value={tab.value}
          >
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>
      {tabs.map((tab) => (
        <TabsContent className="space-y-4" key={tab.value} value={tab.value}>
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-7">
            <div className="col-span-7">{tab.content}</div>
          </div>
        </TabsContent>
      ))}
    </Tabs>
  );
}
