import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import type { Metafield } from '@/schemas/metafield';
import { MetafieldSchema } from '@/schemas/metafield';

import { useAppSelector } from './use-app-selector';

const MetafieldListApiResponseSchema = createApiResponseSchema(
  z.array(MetafieldSchema),
);

interface GetScansParams {
  brandId?: string;
  namespaceId: string;
}

async function getMetafields({ brandId, namespaceId }: GetScansParams) {
  const response = await api.get<Metafield[]>(
    apiPaths.METAFIELDS({ brandId: brandId ?? '', namespaceId }),
  );

  const { data } = MetafieldListApiResponseSchema.parse(response.data);
  return { metafields: data };
}

export function useMetafieldsQuery(namespaceId: string) {
  const selectedBrand = useAppSelector((state) => state.brands.selected);

  return useQuery({
    queryKey: ['metafields', selectedBrand?.id, namespaceId],
    queryFn: () =>
      getMetafields({
        brandId: selectedBrand?.id,
        namespaceId,
      }),
    enabled: Boolean(selectedBrand?.id) && Boolean(namespaceId),
  });
}
