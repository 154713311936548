import { z } from 'zod';

import { formatDate } from '@/helpers/format-date';

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
// export const OrderSchema = z.object({
//   id: z.string(),
//   code: z.string(),
//   status: z.string(),
//   chipModel: z.string(),
//   tidCount: z.number(),
//   availableTidCount: z.number(),
//   createdAt: z.string().transform(formatDate),
//   updatedAt: z.string().transform(formatDate),
// });

export const OrderSchema = z.object({
  id: z.string().uuid(),
  status: z.enum([
    'Pending',
    'Processing',
    'Ready',
    'Shipped',
    'Delivered',
    'Completed',
    'Cancelled',
    'On Hold',
    'Failed',
    'Returned',
    'Refunded',
  ]),
  code: z.string(),
  chipModel: z.string(),
  brandId: z.string().uuid(),
  providerId: z.string().uuid().nullable(),
  tidCount: z.number(),
  availableTidCount: z.number(),
  createdAt: z.string().transform(formatDate),
  updatedAt: z.string().transform(formatDate),
});

export type Order = z.infer<typeof OrderSchema>;
