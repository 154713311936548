import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiPaths } from '@/constants/api-paths';
import { paginationSchema } from '@/data/pagination-schema';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import type { Scan, ScanCounts } from '@/schemas/scan-schema';
import { scanSchema } from '@/schemas/scan-schema';

import { useAppSelector } from './use-app-selector';

const ScanListApiResponseSchema = createApiResponseSchema(
  z.array(scanSchema),
  paginationSchema,
);

interface GetScansParams {
  brandId?: string;
  tidId?: string;
  pagination?: {
    skip?: number;
    take?: number;
  };
  filters?: {
    id: string;
    value: unknown;
  }[];
}

export interface WithPaginationAndCounts<TData> {
  data: TData;
  pagination: {
    page: number;
    pageSize: number;
    total: number;
  };
  counts?: ScanCounts;
}

async function getScans({
  tidId,
  pagination,
  brandId,
  filters,
}: GetScansParams) {
  const response = await api.get<WithPaginationAndCounts<Scan[]>>(
    apiPaths.SCANS({ brandId: brandId ?? '' }),
    {
      params: { tidId, ...pagination, filters },
    },
  );

  const scans = ScanListApiResponseSchema.parse(response.data);

  return {
    scans: scans.data,
    pagination: scans.pagination,
  };
}

export function useScanQuery({ tidId, pagination, filters }: GetScansParams) {
  const selectedBrand = useAppSelector((state) => state.brands.selected);

  return useQuery({
    queryKey: ['scans', selectedBrand?.id, tidId, pagination, filters],
    queryFn: () =>
      getScans({
        brandId: selectedBrand?.id ?? '',
        tidId,
        pagination,
        filters,
      }),
    enabled: Boolean(selectedBrand),
  });
}
