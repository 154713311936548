import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiPaths } from '@/constants/api-paths';
import { paginationSchema } from '@/data/pagination-schema';
import { formatDate } from '@/helpers/format-date';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';

import { useAppSelector } from './use-app-selector';

// Schema for Tid
export const TidSchema = z.object({
  id: z.string().uuid(),
  roll: z.string().nullable(), // roll can be null
  orderId: z.string().uuid(),
  batchId: z.string().uuid().nullable(),
  isActive: z.boolean(),
  sampleInfo: z.any().nullable(), // sampleInfo can be null, adjust if you have a defined structure
  scannedAt: z.string().transform(formatDate).nullable(), // scannedAt can be null
  createdAt: z.string().transform(formatDate),
  updatedAt: z.string().transform(formatDate),
});

export type Tid = z.infer<typeof TidSchema>;

const ProductListApiResponseSchema = createApiResponseSchema(
  z.array(TidSchema),
  paginationSchema,
);

async function getTids({
  brandId,
  productId,
  batchId,
}: {
  brandId: string;
  productId: string;
  batchId: string;
}) {
  const response = await api.get(
    apiPaths.TIDS_NESTED({ brandId, productId, batchId }),
  );

  const { data, pagination } = ProductListApiResponseSchema.parse(
    response.data,
  );

  return {
    tags: data,
    pagination,
  };
}

export function useTidsNestedQuery({
  productId,
  batchId,
}: {
  productId: string;
  batchId: string;
}) {
  const selectedBrand = useAppSelector((state) => state.brands.selected);

  return useQuery({
    queryKey: ['tids', selectedBrand?.id, productId, batchId],
    queryFn: () =>
      getTids({
        brandId: selectedBrand?.id ?? '',
        productId,
        batchId,
      }),
    enabled: Boolean(selectedBrand?.id),
  });
}
