import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import type { CreateTagFormData } from '@/pages/dashboard/tags';
import type { Product } from '@/schemas/product';
import { TidSchema } from '@/schemas/tid-schema';

import { useAppSelector } from './use-app-selector';
import { toast } from './use-toast';

interface CreateTidParams {
  brandId: string;
  productId: string;
  batchId: string;
  uid: string;
}

async function createTid({
  brandId,
  productId,
  batchId,
  ...tid
}: CreateTidParams) {
  const { data } = await api.post<Product>(
    apiPaths.TIDS_NESTED({ brandId, productId, batchId }),
    {
      ...tid,
    },
  );

  return TidSchema.parse(data);
}

export function useCreateTagMutation() {
  const selectedBrand = useAppSelector((state) => state.brands.selected);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ batchId, productId, ...tid }: CreateTagFormData) =>
      createTid({
        brandId: selectedBrand?.id ?? '',
        productId,
        batchId,
        ...tid,
      }),
    mutationKey: ['create-tid'],
    onSuccess: () => {
      toast({
        title: 'Tag created',
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['batches', selectedBrand?.id],
      });
    },
  });
}
