import { z } from 'zod';

import { formatDate } from '@/helpers/format-date';

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.

export const MetafieldTypeSchema = z.union([
  z.literal('string'),
  z.literal('number'),
  z.literal('boolean'),
  z.literal('list'),
]);

export const MetafieldSchema = z.object({
  id: z.string(),
  key: z.string(),
  type: MetafieldTypeSchema,
  listOptions: z.string().array().nullable(),
  isFavorite: z.boolean(),
  createdAt: z.string().transform(formatDate),
  updatedAt: z.string().transform(formatDate),
});

export type Metafield = z.infer<typeof MetafieldSchema>;

export type MetafieldRequestParams = Omit<
  Metafield,
  'id' | 'createdAt' | 'updatedAt'
>;
