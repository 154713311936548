import type { BatchMetafield } from '@/hooks/use-batch-query';

export function getMetafields(batchMetafields?: BatchMetafield[] | null) {
  if (!batchMetafields) return [];
  return batchMetafields.map((bm) => {
    return {
      key: bm.metafield.key,
      value: bm.value.value,
    };
  });
}
