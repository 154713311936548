import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiPaths } from '@/constants/api-paths';
import { paginationSchema } from '@/data/pagination-schema';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import type { Order } from '@/schemas/order';
import { OrderSchema } from '@/schemas/order';

import { useAppSelector } from './use-app-selector';

const OrderListApiResponseSchema = createApiResponseSchema(
  z.array(OrderSchema),
  paginationSchema,
);

interface GetScansParams {
  brandId?: string;
}

async function getOrders({ brandId }: GetScansParams) {
  const response = await api.get<Order[]>(
    apiPaths.ORDERS({ brandId: brandId ?? '' }),
  );

  const { data } = OrderListApiResponseSchema.parse(response.data);
  return { orders: data };
}

export function useOrdersQuery() {
  const selectedBrand = useAppSelector((state) => state.brands.selected);

  return useQuery({
    queryKey: ['orders', selectedBrand?.id],
    queryFn: () =>
      getOrders({
        brandId: selectedBrand?.id,
      }),
    enabled: Boolean(selectedBrand?.id),
  });
}
