import { createSlice } from '@reduxjs/toolkit';

import type { Brand } from '@/schemas/brand';

import type { PayloadAction } from '@reduxjs/toolkit';

export interface CompaniesState {
  selected?: Brand;
}

const initialState: CompaniesState = {
  selected: undefined,
};

export const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    selectBrand: (state, action: PayloadAction<Brand>) => {
      state.selected = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { selectBrand } = brandsSlice.actions;

export default brandsSlice.reducer;
