import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

export type DialogType =
  | 'create-product'
  | 'edit-product'
  | 'upload-image'
  | 'create-batch'
  | 'create-tag';

export interface DialogState {
  selected?: DialogType;
}

const initialState: DialogState = {
  selected: undefined,
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    selectDialog: (state, action: PayloadAction<DialogType | undefined>) => {
      state.selected = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { selectDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
