import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiPaths } from '@/constants/api-paths';
import { paginationSchema } from '@/data/pagination-schema';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import type { Product } from '@/schemas/product';
import { ProductSchema } from '@/schemas/product';

import { useAppSelector } from './use-app-selector';

const ProductListApiResponseSchema = createApiResponseSchema(
  z.array(ProductSchema),
  paginationSchema,
);

interface ProductsParams {
  brandId?: string;
}

export type WithPaginationAndFilters<TParam> = TParam & {
  pagination?: {
    skip?: number;
    take?: number;
  };
  filters?: {
    id: string;
    value: unknown;
  }[];
};

async function getProducts({
  brandId,
  filters,
  pagination,
}: WithPaginationAndFilters<ProductsParams>) {
  const response = await api.get<Product[]>(
    apiPaths.PRODUCTS({ brandId: brandId ?? '' }),
    {
      params: { ...pagination, filters },
    },
  );

  const products = ProductListApiResponseSchema.parse(response.data);
  return { products: products.data, pagination: products.pagination };
}

export function useProductsQuery(
  params: WithPaginationAndFilters<ProductsParams> = {
    pagination: { take: 10, skip: 0 },
    filters: [],
  },
) {
  const selectedBrand = useAppSelector((state) => state.brands.selected);

  return useQuery({
    queryKey: [
      'products',
      selectedBrand?.id,
      params.filters,
      params.pagination,
    ],
    queryFn: () =>
      getProducts({
        brandId: selectedBrand?.id,
        filters: params.filters,
        pagination: params.pagination,
      }),
    enabled: Boolean(selectedBrand?.id),
  });
}
