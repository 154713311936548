import { usePasswordless } from 'amazon-cognito-passwordless-auth/react';

import { useAppSelector } from '@/hooks/use-app-selector';
import { useCompany } from '@/hooks/use-company';

import { SupportIFrame } from './components/support-iframe';

export function Support() {
  const { data } = useCompany();
  const { tokensParsed } = usePasswordless();
  const selectedBrand = useAppSelector((state) => state.brands.selected);
  const brandId = selectedBrand?.id;
  const crmCompanyID = data?.company.companyCrmId;
  const email = tokensParsed?.idToken.email;
  // TODO: Change this to Dynamic Value
  const ACCOUNT_NAME = 'PICCADILY AGRO INDUSTRIES LIMITED';

  if (!brandId || !crmCompanyID || !email) return null;

  return (
    <div className="space-y-4 p-8 pt-6 flex flex-col items-center h-screen justify-center w-full flex-1">
      <h2 className="text-3xl font-bold tracking-tight">Support</h2>
      <SupportIFrame
        accountName={ACCOUNT_NAME}
        brandId={brandId}
        crmCompanyId={crmCompanyID}
        email={email}
      />
    </div>
  );
}
