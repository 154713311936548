import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToast } from '@/components/ui/use-toast';
import { apiPaths } from '@/constants/api-paths';
import { getZodErrorMessages } from '@/helpers/get-zod-error-messages';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import { MetafieldSchema } from '@/schemas/metafield';
import type { MetafieldRequestParams, Metafield } from '@/schemas/metafield';

import { useAppSelector } from './use-app-selector';

import type { AxiosError } from 'axios';
import type { ZodError } from 'zod';

const MetafieldApiResponseSchema = createApiResponseSchema(MetafieldSchema);

async function createMetafield({
  namespaceId,
  brandId,
  ...metafield
}: MetafieldRequestParams & { brandId: string; namespaceId: string }) {
  const { data } = await api.post<Metafield>(
    apiPaths.METAFIELDS({ brandId, namespaceId }),
    {
      ...metafield,
    },
  );

  return MetafieldApiResponseSchema.parse(data);
}

export function useCreateMetafieldMutation() {
  const { toast } = useToast();
  const selectedBrand = useAppSelector((state) => state.brands.selected);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (metafield: MetafieldRequestParams & { namespaceId: string }) =>
      createMetafield({ ...metafield, brandId: selectedBrand?.id ?? '' }),
    mutationKey: ['create-metafield'],
    onSuccess: () => {
      toast({
        title: 'Metafield created',
      });
    },
    onError: (error: AxiosError<ZodError>) => {
      // Extract Zod validation messages if present
      const validationMessages = getZodErrorMessages(error);

      if (validationMessages.length) {
        // Display a toast notification for each validation issue
        validationMessages.forEach((message) => {
          toast({
            title: 'Error creating metafield',
            description: message,
            variant: 'destructive',
          });
        });
      } else {
        // Handle general error
        const defaultMessage = error.message || 'Something went wrong!';
        toast({
          title: 'Error creating metafield',
          description: defaultMessage,
          variant: 'destructive',
        });
      }
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['metafields', selectedBrand?.id],
      });
    },
  });
}
