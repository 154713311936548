import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

export function Charts() {
  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-12">
      <Card className="col-span-12">
        <CardHeader>
          <CardTitle>Scans Overtime</CardTitle>
        </CardHeader>
        <CardContent className="pl-2">
          <iframe
            height={170}
            src="https://analytics.zoho.com/open-view/2185350000007001706/bb4277bab7a2138288dc371216a542ea"
            title="scan-by-day"
            width="100%"
          />
        </CardContent>
      </Card>
      <Card className="col-span-6">
        <CardHeader>
          <CardTitle>Scans by Status</CardTitle>
        </CardHeader>
        <CardContent className="pl-2">
          <iframe
            height={255}
            src="https://analytics.zoho.com/open-view/2185350000007001459/9c6da499ba0bb4788a0ed4f609ec5641"
            title="scan-by-status"
            width="100%"
          />
        </CardContent>
      </Card>
      <Card className="col-span-3">
        <CardHeader>
          <CardTitle>Scans by Country</CardTitle>
        </CardHeader>
        <CardContent className="pl-2">
          <iframe
            height={255}
            src="https://analytics.zoho.com/open-view/2185350000007001334/99fb94b1d2be739e00292ec8d76ae889"
            title="scan-by-country"
            width="100%"
          />
        </CardContent>
      </Card>
      <Card className="col-span-3">
        <CardHeader>
          <CardTitle>Top Scanning Cities</CardTitle>
        </CardHeader>
        <CardContent className="pl-2">
          <iframe
            height={255}
            src="https://analytics.zoho.com/open-view/2185350000007001989/59cc29b3df766587665b825850caea13"
            title="top-scanning-cities"
            width="100%"
          />
        </CardContent>
      </Card>
    </div>
  );
}
