import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import type { Namespace } from '@/schemas/namespace';
import { NamespaceSchema } from '@/schemas/namespace';

import { useAppSelector } from './use-app-selector';

const NamespaceListApiResponseSchema = createApiResponseSchema(
  z.array(NamespaceSchema),
);

interface GetScansParams {
  brandId?: string;
}

async function getNamespaces({ brandId }: GetScansParams) {
  const response = await api.get<Namespace[]>(
    apiPaths.NAMESPACES({ brandId: brandId ?? '' }),
  );

  const { data } = NamespaceListApiResponseSchema.parse(response.data);
  return { namespaces: data };
}

export function useNamespacesQuery() {
  const selectedBrand = useAppSelector((state) => state.brands.selected);

  return useQuery({
    queryKey: ['namespaces', selectedBrand?.id],
    queryFn: () =>
      getNamespaces({
        brandId: selectedBrand?.id,
      }),
    enabled: Boolean(selectedBrand?.id),
  });
}
