import {
  CheckCircle,
  NfcIcon,
  TagIcon,
  RefreshCw,
  XCircle,
  AlertCircle,
} from 'lucide-react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { useIndicatorsQuery } from '@/hooks/use-indicators-query';
import { useScanQuery } from '@/hooks/use-scan-query';
import { useTidsQuery } from '@/hooks/use-tids-query';
import { cn } from '@/lib/utils';

import { Card, CardHeader, CardTitle, CardContent } from './ui/card';

export function Indicators() {
  const { tidId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchStatus = searchParams.get('status');

  const filterByStatusHandler = (status: string) => {
    if (location.pathname.includes('scans')) {
      setSearchParams(searchStatus !== status ? { status } : undefined);
      return;
    }

    navigate(`/admin/scans?status=${status}`);
  };

  const clearFilterHandler = () => {
    if (location.pathname.includes('scans')) {
      setSearchParams();
      return;
    }

    navigate('/admin/scans');
  };

  const { data } = useIndicatorsQuery({
    tidId,
  });

  const getClassNames = (status: string) =>
    cn(
      searchStatus === status
        ? 'border-primary'
        : 'hover:border-secondary-foreground',
      'cursor-pointer transition-all',
    );

  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-6">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Active Tags</CardTitle>
          <TagIcon size="1rem" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {data?.indicators.totalTids ?? 0}
          </div>
          {/* <p className="text-xs text-muted-foreground">
            +20.1% from last month
          </p> */}
        </CardContent>
      </Card>
      <Card
        className={getClassNames('')}
        onClick={clearFilterHandler}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            clearFilterHandler();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Total Scans</CardTitle>
          <NfcIcon size="1rem" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {data?.indicators.totalScans ?? 0}
          </div>
          {/* <p className="text-xs text-muted-foreground">
            +20.1% from last month
          </p> */}
        </CardContent>
      </Card>
      <Card
        className={getClassNames('authenticated')}
        onClick={() => {
          filterByStatusHandler('authenticated');
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            filterByStatusHandler('authenticated');
          }
        }}
        role="button"
        tabIndex={0}
      >
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Authentic</CardTitle>
          <CheckCircle className="stroke-green-600" size="1rem" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {data?.indicators.authenticated ?? 0}
          </div>
          {/* <p className="text-xs text-muted-foreground">
            +180.1% from last month
          </p> */}
        </CardContent>
      </Card>
      <Card
        className={getClassNames('rescan')}
        onClick={() => {
          filterByStatusHandler('rescan');
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            filterByStatusHandler('rescan');
          }
        }}
        role="button"
        tabIndex={0}
      >
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Rescan</CardTitle>
          <RefreshCw className="stroke-amber-600" size="1rem" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {data?.indicators.rescan ?? 0}
          </div>
          {/* <p className="text-xs text-muted-foreground">+19% from last month</p> */}
        </CardContent>
      </Card>
      <Card
        className={getClassNames('counterfeit')}
        onClick={() => {
          filterByStatusHandler('counterfeit');
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            filterByStatusHandler('counterfeit');
          }
        }}
        role="button"
        tabIndex={0}
      >
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Counterfeit</CardTitle>
          <AlertCircle className="stroke-red-600" size="1rem" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {data?.indicators.counterfeit ?? 0}
          </div>
          {/* <p className="text-xs text-muted-foreground">-0.9% from last month</p> */}
        </CardContent>
      </Card>
      <Card
        className={getClassNames('invalid')}
        onClick={() => {
          filterByStatusHandler('invalid');
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            filterByStatusHandler('invalid');
          }
        }}
        role="button"
        tabIndex={0}
      >
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Invalid</CardTitle>
          <XCircle className="stroke-gray-600" size="1rem" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {data?.indicators.invalid ?? 0}
          </div>
          {/* <p className="text-xs text-muted-foreground">-0.9% from last month</p> */}
        </CardContent>
      </Card>
    </div>
  );
}
