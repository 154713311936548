import { useQuery } from '@tanstack/react-query';

import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import { TidSchema } from '@/schemas/tid-schema';

import { useAppSelector } from './use-app-selector';

interface GetScansParams {
  brandId: string;
  tidId: string;
}

const TidApiResponseSchema = createApiResponseSchema(TidSchema);

async function getTid({ brandId, tidId }: GetScansParams) {
  const response = await api.get(apiPaths.TIDS_BY_ID({ brandId, tidId }));

  const { data } = TidApiResponseSchema.parse(response.data);

  return {
    tid: data,
  };
}

export function useTidQuery(tidId?: string) {
  const selectedBrand = useAppSelector((state) => state.brands.selected);

  return useQuery({
    queryKey: ['tid', selectedBrand?.id, tidId],
    queryFn: () =>
      getTid({
        brandId: selectedBrand?.id ?? '',
        tidId: tidId ?? '',
      }),
    enabled: Boolean(selectedBrand?.id) && Boolean(tidId),
  });
}
