import { Upload, File, X } from 'lucide-react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { Button } from '@/components/ui/button';

import { ButtonLoading } from '../button-loading';

export function FileUploadForm({
  file,
  setFile,
  handleUpload,
  isSubmitting,
}: {
  file: File | null;
  setFile: (file: File | null) => void;
  handleUpload: () => void;
  isSubmitting?: boolean;
}) {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles[0]) {
        setFile(acceptedFiles[0]);
      }
    },
    [setFile],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFilePicker,
  } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
    },
    multiple: false,
    noClick: true,
  });

  return (
    <div
      aria-label="File upload area"
      className="flex flex-col items-center justify-center gap-4"
      role="region"
    >
      <div
        {...getRootProps()}
        aria-describedby="upload-instructions"
        className={`p-8 border-2 border-dashed rounded-lg text-center cursor-pointer transition-colors
          ${
            isDragActive
              ? 'border-primary bg-primary/10'
              : 'border-muted-foreground hover:border-primary hover:bg-primary/5'
          }`}
        onClick={openFilePicker}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            openFilePicker();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <input
          {...getInputProps()}
          accept=".csv"
          aria-label="File upload input"
        />
        <span className="sr-only" id="upload-instructions">
          Press Enter or Space to select a CSV file, or drag and drop a file
          here
        </span>
        {file ? (
          <div
            aria-label="Selected file"
            className="flex items-center justify-between"
            role="status"
          >
            <div className="flex items-center">
              <File aria-hidden="true" className="mr-2 h-4 w-4" />
              <span>{file.name}</span>
            </div>
            <Button
              aria-label="Remove selected file"
              onClick={(e) => {
                e.stopPropagation();
                setFile(null);
              }}
              size="icon"
              variant="ghost"
            >
              <X aria-hidden="true" className="h-4 w-4" />
            </Button>
          </div>
        ) : (
          <div>
            <Upload
              aria-hidden="true"
              className="mx-auto h-12 w-12 text-muted-foreground"
            />
            <p className="mt-2">
              Drag & drop a CSV file here, or click to select one
            </p>
          </div>
        )}
      </div>
      {isSubmitting ? (
        <ButtonLoading className="place-self-end" />
      ) : (
        <Button
          aria-label={
            file ? 'Upload selected file' : 'Upload button (no file selected)'
          }
          className="place-self-end"
          disabled={!file}
          onClick={handleUpload}
        >
          Upload
        </Button>
      )}

      <div aria-live="polite" className="sr-only">
        {isDragActive ? 'Release to drop the file' : ''}
      </div>
    </div>
  );
}
