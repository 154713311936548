import { configureStore } from '@reduxjs/toolkit';

import brandsReducer from './brands/brands.slice';
import dialogReducer from './dialog/dialog.slice';
import productsReducer from './products/products.slice';

export const store = configureStore({
  reducer: {
    brands: brandsReducer,
    dialog: dialogReducer,
    products: productsReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
