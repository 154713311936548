import { AlertCircle, CheckCircle, RefreshCw, XCircle } from 'lucide-react';

export const statuses = [
  {
    value: 'authenticated',
    label: 'Authentic',
    icon: CheckCircle,
    className: 'stroke-green-600',
  },
  {
    value: 'counterfeit',
    label: 'Counterfeit',
    icon: AlertCircle,
    className: 'stroke-red-600',
  },
  {
    value: 'rescan',
    label: 'Rescan',
    icon: RefreshCw,
    className: 'stroke-amber-600',
  },
  {
    value: 'invalid',
    label: 'Invalid',
    icon: XCircle,
    className: 'stroke-gray-600',
  },
];
