import { z } from 'zod';

import type { ZodType, AnyZodObject } from 'zod';

// Helper type to define an empty Zod schema
const EmptySchema = z.object({});

// Generic function to create an API response schema with data and metadata
export function createApiResponseSchema<
  DataSchema extends ZodType,
  PaginationSchema extends AnyZodObject = typeof EmptySchema,
>(
  dataSchema: DataSchema,
  paginationSchema: PaginationSchema = EmptySchema as PaginationSchema,
) {
  return z.object({
    status: z.string(),
    message: z.string(),
    data: dataSchema,
    pagination: paginationSchema.optional(),
  });
}
