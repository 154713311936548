import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiPaths } from '@/constants/api-paths';
import { paginationSchema } from '@/data/pagination-schema';
import { formatDate } from '@/helpers/format-date';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import { BatchesArraySchema } from '@/schemas/batch-nested';

import { useAppSelector } from './use-app-selector';

import type { WithPaginationAndFilters } from './use-products-query';

const ProductSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  brandId: z.string().uuid(),
  sku: z.string(),
  image: z.string().optional(),
  templateId: z.string().uuid().nullable(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

const BatchResponseSchema = z.object({
  id: z.string().uuid(),
  lot: z.string(),
  expiresAt: z.string().transform(formatDate).nullable(),
  producedAt: z.string().transform(formatDate).nullable(),
  tags: z.array(z.string()).nullable(),
  brandId: z.string().uuid(),
  productId: z.string().uuid(),
  product: ProductSchema,
  batchMetafields: z.array(z.unknown()), // Adjust type based on actual metafield structure
  createdAt: z.string().transform(formatDate),
  updatedAt: z.string().transform(formatDate),
  tidCount: z.number().int(),
});

// Example usage
export type BatchResponse = z.infer<typeof BatchResponseSchema>;

const BatchListApiResponseSchema = createApiResponseSchema(
  z.array(BatchResponseSchema),
  paginationSchema,
);

async function getBatches({
  brandId,
  productId,
  pagination,
  filters,
}: WithPaginationAndFilters<
  Required<{
    brandId: string;
    productId: string;
  }>
>) {
  const response = await api.get(
    apiPaths.BATCHES_NESTED({ brandId, productId }),
    {
      params: { ...pagination, filters },
    },
  );

  const batches = BatchListApiResponseSchema.parse(response.data);

  return {
    batches: batches.data,
    pagination: batches.pagination,
  };
}

export function useBatchesNestedQuery({
  productId,
  pagination,
  filters,
}: WithPaginationAndFilters<
  Partial<{
    brandId: string;
    productId: string;
  }>
>) {
  const brandId = useAppSelector((state) => state.brands.selected?.id);

  return useQuery({
    queryKey: ['batches', brandId, productId, pagination, filters],
    queryFn: () =>
      getBatches({
        brandId: brandId ?? '',
        productId: productId ?? '',
        pagination,
        filters,
      }),
    enabled: Boolean(brandId) && Boolean(productId),
  });
}
