import { zodResolver } from '@hookform/resolvers/zod';
import { Plus, X } from 'lucide-react';
import { useState } from 'react';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useAvailableRolls } from '@/hooks/use-available-rolls-query';
import { useMetafieldsQuery } from '@/hooks/use-metafields-query';
import { useNamespacesQuery } from '@/hooks/use-namespaces-query';
import { useProductQuery } from '@/hooks/use-product-query';
import { useProductsQuery } from '@/hooks/use-products-query';
import { cn } from '@/lib/utils';

import { Badge } from '../ui/badge';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Label } from '../ui/label';
import { ScrollArea } from '../ui/scroll-area';

import type { UseFormReturn } from 'react-hook-form';

const step1Schema = z.object({
  productId: z.string().min(1, 'Product is required'),
  howManyProducts: z.number().min(1, 'At least one product is required'),
  lot: z.string().min(1, 'Lot number is required'),
  producedAt: z.string().optional(),
});

const step2Schema = z.object({
  metafields: z
    .array(
      z.object({
        metafieldId: z.string().min(1, 'Key is required').optional(),
        value: z.string().min(1, 'Value is required').optional(),
      }),
    )
    .optional(),
  tags: z.array(z.string()).optional(),
});

const step3Schema = z.object({
  rolls: z.array(z.string()).min(1),
});

// For the summary, you may not need a schema, but you can combine all schemas if you want.
export const batchSchema = step1Schema.merge(step2Schema).merge(step3Schema);

const getSchema = (page: number) => {
  switch (page) {
    case 1:
      return step1Schema;
    case 2:
      return step2Schema;
    case 3:
      return step3Schema;
    default:
      return batchSchema;
  }
};

export type BatchFormData = z.infer<typeof batchSchema>;

export function BatchForm({
  onCreateBatch,
}: {
  onCreateBatch: (batch: BatchFormData) => void;
}) {
  const { productId } = useParams();
  const [page, setPage] = useState(1);
  const form = useForm<BatchFormData>({
    resolver: zodResolver(getSchema(page)),
    defaultValues: {
      productId,
      howManyProducts: 0,
      lot: '',
      producedAt: '',
      metafields: [],
      tags: [],
      rolls: [],
    },
    mode: 'onChange', // This mode checks validation on every change
  });

  const isLastPage = page === 4;

  const onSubmit = form.handleSubmit((data) => {
    if (isLastPage) {
      onCreateBatch(data);
    } else {
      setPage(page + 1); // Only move to the next page if validation passes
    }
  });

  return (
    <Form {...form}>
      <form
        className="space-y-8"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {/* Step Indicator */}
        <div className="relative flex justify-between mb-8">
          {[1, 2, 3, 4].map((step) => (
            <div className="flex flex-col items-center" key={step}>
              <div
                className={`z-10 flex items-center justify-center w-8 h-8 rounded-full border-2 ${
                  page >= step
                    ? 'bg-primary border-primary text-primary-foreground'
                    : 'bg-background border-gray-300 text-gray-400'
                }`}
              >
                {step}
              </div>
              <div className="mt-2 text-xs font-medium text-gray-500">
                Step {step}
              </div>
            </div>
          ))}
          <div className="absolute top-4 left-0 w-full h-0.5 bg-gray-200 -z-10">
            <div
              className="h-full bg-primary transition-all duration-300 ease-in-out"
              style={{ width: `${((page - 1) / 3) * 100}%` }}
            />
          </div>
        </div>

        {/* Step Content */}
        {page === 1 && <Step1 form={form} />}
        {page === 2 && <Step2 form={form} />}
        {page === 3 && <Step3 form={form} />}
        {page === 4 && <Step4Summary form={form} />}

        {/* Navigation Buttons */}
        <div className="flex justify-between mt-4">
          {page > 1 && (
            <Button
              onClick={() => {
                setPage(page - 1);
              }}
              type="button"
              variant="outline"
            >
              Back
            </Button>
          )}
          <Button
            disabled={!form.formState.isValid} // Disable until form is valid
            onClick={onSubmit}
            type={isLastPage ? 'submit' : 'button'}
          >
            {isLastPage ? 'Submit' : 'Next'}
          </Button>
        </div>
      </form>
    </Form>
  );
}

function Step1({ form }: StepProps) {
  const productsQuery = useProductsQuery();
  return (
    <>
      <h2 className="font-bold mb-6">1. Batch Details</h2>
      <div className="grid grid-cols-6 gap-4">
        <FormField
          control={form.control}
          name="productId"
          render={({ field }) => (
            <FormItem className="col-span-4">
              <FormLabel>Product</FormLabel>
              <Select defaultValue={field.value} onValueChange={field.onChange}>
                <FormControl>
                  <SelectTrigger disabled>
                    <SelectValue placeholder="Select Product Tagged in this Batch" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {productsQuery.data?.products.map((product) => (
                    <SelectItem key={product.id} value={product.id}>
                      {product.name} ({product.sku})
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="howManyProducts"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel>How Many Items?</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  {...field}
                  onChange={(e) => {
                    field.onChange(parseInt(e.target.value));
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <FormField
          control={form.control}
          name="lot"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Lot Number</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="producedAt"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Production Date</FormLabel>
              <FormControl>
                <Input type="datetime-local" {...field} />
              </FormControl>
              <FormDescription>Date the product was created</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </>
  );
}

interface StepProps {
  form: UseFormReturn<BatchFormData>;
}

function Step2({ form }: StepProps) {
  const [tagInput, setTagInput] = useState(''); // Separate state for the input box text
  const namespacesQuery = useNamespacesQuery();
  const namespaceId = namespacesQuery.data?.namespaces[0].id || '';
  const metafieldsQuery = useMetafieldsQuery(namespaceId);

  const handleTagInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTagInput(value);

    if (value.endsWith(',')) {
      const newTag = value.slice(0, -1).trim();
      const currentTags = form.getValues('tags') || [];

      if (newTag && !currentTags.includes(newTag)) {
        form.setValue('tags', [...currentTags, newTag]);
      }
      setTagInput(''); // Clear the input box after adding the tag
    }
  };

  const removeTag = (tagToRemove: string) => {
    const updatedTags = (form.getValues('tags') || []).filter(
      (tag) => tag !== tagToRemove,
    );
    form.setValue('tags', updatedTags);
  };

  return (
    <>
      <h2 className="text-2xl font-bold mb-6">Additional Metafields</h2>
      {metafieldsQuery.data?.metafields.map((metafield, index) => (
        <FormField
          control={form.control}
          key={metafield.id}
          name={`metafields.${index}.value`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{metafield.key}</FormLabel>
              <Controller
                control={form.control}
                name={`metafields.${index}.value`}
                render={({ field }) =>
                  metafield.type === 'list' ? (
                    <Select
                      onValueChange={(value) => {
                        form.setValue(`metafields.${index}`, {
                          metafieldId: metafield.id,
                          value,
                        });
                      }}
                      value={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select Option" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {metafield.listOptions?.map((option) => (
                          <SelectItem key={option} value={option}>
                            {option}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  ) : (
                    <Input
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        form.setValue(`metafields.${index}`, {
                          metafieldId: metafield.id,
                          value: e.target.value,
                        });
                      }}
                      placeholder="Enter value"
                    />
                  )
                }
              />
              <FormMessage />
            </FormItem>
          )}
        />
      ))}
      <FormField
        control={form.control}
        name="tags"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Internal Tags</FormLabel>
            <FormControl>
              <div className="flex flex-wrap items-center gap-2 p-2 border rounded-md focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
                {Array.isArray(field.value) &&
                  field.value.map((tag, index) => (
                    <Badge className="text-sm" key={index} variant="secondary">
                      {tag}
                      <button
                        className="ml-1 text-xs font-bold"
                        onClick={() => {
                          removeTag(tag);
                        }}
                        type="button"
                      >
                        ×
                      </button>
                    </Badge>
                  ))}
                <Input
                  {...field}
                  className="flex-1 border-0 focus-visible:ring-0 focus-visible:ring-offset-0"
                  onChange={handleTagInput}
                  placeholder="Type a tag and press comma to add"
                  value={tagInput}
                />
              </div>
            </FormControl>
            <FormDescription>
              Internal tags for organizing and grouping batch data for reporting
              and filtering. Type a tag and press comma to add.
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}

function Step3({ form }: StepProps) {
  const availableRollsQuery = useAvailableRolls();
  return (
    <>
      <h2 className="text-2xl font-bold mb-6">InfoTap Label Information</h2>
      <p className="mb-4">
        Please select the Tag Roll Serial Numbers used in this Batch.
      </p>
      <div className="space-y-2 ">
        <Label>Available Rolls</Label>
        <ScrollArea className="h-[300px] border rounded-md p-4">
          {!availableRollsQuery.isLoading &&
          !availableRollsQuery.data?.availableRolls.length ? (
            <div className="grid grid-1 gap-2">
              <Badge className="text-sm" variant="destructive">
                No Rolls Available
              </Badge>
            </div>
          ) : (
            <div className="grid grid-cols-3 gap-2">
              <Controller
                control={form.control}
                name="rolls"
                render={({ field }) => (
                  <>
                    {availableRollsQuery.data?.availableRolls.map((roll) => {
                      const isSelected = field.value.includes(roll.roll);
                      return (
                        <Badge
                          className={cn(
                            'text-sm cursor-pointer transition-all',
                          )}
                          key={roll.roll}
                          onClick={() => {
                            const newValue = isSelected
                              ? field.value.filter((v) => v !== roll.roll)
                              : [...field.value, roll.roll];
                            field.onChange(newValue);
                          }}
                          variant={isSelected ? 'secondary' : 'outline'}
                        >
                          {roll.roll}
                          {isSelected ? (
                            <X className="ml-2 h-4 w-4" />
                          ) : (
                            <Plus className="ml-2 h-4 w-4" />
                          )}
                        </Badge>
                      );
                    })}
                  </>
                )}
              />
            </div>
          )}
        </ScrollArea>
      </div>
    </>
  );
}

function Step4Summary({ form }: StepProps) {
  const { productId } = useParams();
  const namespacesQuery = useNamespacesQuery();
  const namespaceId = namespacesQuery.data?.namespaces[0].id || '';
  const metafieldsQuery = useMetafieldsQuery(namespaceId);
  const selectedBrandId = useAppSelector((state) => state.brands.selected?.id);
  const productQuery = useProductQuery({
    productId: productId || '',
    brandId: selectedBrandId || '',
  });

  const metafields = form.getValues('metafields') || [];
  const tags = form.getValues('tags') || [];

  return (
    <>
      <h2 className="text-2xl font-bold mb-6">Confirm Details</h2>
      <Card>
        <CardHeader>
          <CardTitle>Batch Details Summary</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h3 className="font-semibold">Product:</h3>
              <p>{productQuery.data?.product.name || 'Not specified'}</p>
            </div>
            <div>
              <h3 className="font-semibold">How Many Products:</h3>
              <p>{form.getValues('howManyProducts') || 'Not specified'}</p>
            </div>
            <div>
              <h3 className="font-semibold">Lot Number:</h3>
              <p>{form.getValues('lot') || 'Not specified'}</p>
            </div>
            <div>
              <h3 className="font-semibold">Production Date:</h3>
              <p>{form.getValues('producedAt') || 'Not specified'}</p>
            </div>
            {metafields.map((metafield, index) =>
              metafield.value ? (
                <div key={index}>
                  <h3 className="font-semibold">
                    {metafieldsQuery.data?.metafields[index].key || 'Unknown'}:
                  </h3>
                  <p>{metafield.value}</p>
                </div>
              ) : null,
            )}
            <div>
              <h3 className="font-semibold">Internal Tags:</h3>
              <p>{tags.join(', ')}</p>
            </div>
            <div>
              <h3 className="font-semibold">Tag Roll Serial Numbers:</h3>
              <p>{form.getValues('rolls').join(', ')}</p>
            </div>
          </div>
        </CardContent>
      </Card>
      <p className="mt-4 text-sm text-muted-foreground">
        Please review the information above. If you need to make any changes,
        use the "Back" button to return to previous steps.
      </p>
    </>
  );
}

// export function BatchFormOld({
//   onCreateBatch,
// }: {
//   onCreateBatch: (batch: BatchFormData) => void;
// }) {
//   const [page, setPage] = useState(1);
//   const [tags, setTags] = useState<string[]>([]);
//   const namespacesQuery = useNamespacesQuery();
//   // TODO: Use Dynamic namespace id based on the route params
//   const namespaceId = namespacesQuery.data?.namespaces[0].id || '';
//   const metafieldsQuery = useMetafieldsQuery(namespaceId);

//   const { productId } = useParams();
//   const productsQuery = useProductsQuery();
//   const selectedBrandId = useAppSelector((state) => state.brands.selected?.id);
//   const productQuery = useProductQuery({
//     productId: productId || '',
//     brandId: selectedBrandId || '',
//   });

//   const form = useForm<z.infer<typeof formSchema>>({
//     resolver: zodResolver(formSchema),
//     defaultValues: {
//       productId,
//       howManyProducts: 0,
//       batchDateTime: '',
//       lot: '',
//       producedAt: '',
//       metafields:
//         metafieldsQuery.data?.metafields.map((field) => ({
//           id: field.id,
//           value: '',
//         })) || [],
//       tags: '',
//       rolls: [],
//     },
//   });

//   const handleTagInput = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const value = e.target.value;
//     if (value.endsWith(',')) {
//       const newTag = value.slice(0, -1).trim();
//       if (newTag && !tags.includes(newTag)) {
//         setTags([...tags, newTag]);
//         form.setValue('tags', '');
//       } else {
//         form.setValue('tags', '');
//       }
//     } else {
//       form.setValue('tags', value);
//     }
//   };

//   const removeTag = (tagToRemove: string) => {
//     setTags(tags.filter((tag) => tag !== tagToRemove));
//   };

//   function onSubmit(values: z.infer<typeof formSchema>) {
//     onCreateBatch(values);
//   }

//   return (
//     <Form {...form}>
//       <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
//         <div className="relative flex justify-between mb-8">
//           {[1, 2, 3, 4].map((step) => (
//             <div className="flex flex-col items-center" key={step}>
//               <div
//                 className={`z-10 flex items-center justify-center w-8 h-8 rounded-full border-2 ${
//                   page >= step
//                     ? 'bg-primary border-primary text-primary-foreground'
//                     : 'bg-background border-gray-300 text-gray-400'
//                 }`}
//               >
//                 {step}
//               </div>
//               <div className="mt-2 text-xs font-medium text-gray-500">
//                 Step {step}
//               </div>
//             </div>
//           ))}
//           <div className="absolute top-4 left-0 w-full h-0.5 bg-gray-200 -z-10">
//             <div
//               className="h-full bg-primary transition-all duration-300 ease-in-out"
//               style={{ width: `${((page - 1) / 3) * 100}%` }}
//             />
//           </div>
//         </div>

//         {page === 1 && (
//           <>
//             <h2 className="font-bold mb-6">1. Batch Details</h2>
//             <FormField
//               control={form.control}
//               name="productId"
//               render={({ field }) => (
//                 <FormItem>
//                   <FormLabel>Product</FormLabel>
//                   <Select
//                     defaultValue={field.value}
//                     onValueChange={field.onChange}
//                   >
//                     <FormControl>
//                       <SelectTrigger disabled>
//                         <SelectValue placeholder="Select Product Tagged in this Batch" />
//                       </SelectTrigger>
//                     </FormControl>
//                     <SelectContent>
//                       {productsQuery.data?.products.map((product) => (
//                         <SelectItem key={product.id} value={product.id}>
//                           {product.name} ({product.sku})
//                         </SelectItem>
//                       ))}
//                     </SelectContent>
//                   </Select>
//                   <FormMessage />
//                 </FormItem>
//               )}
//             />
//             <div className="grid grid-cols-2 gap-4">
//               <FormField
//                 control={form.control}
//                 name="howManyProducts"
//                 render={({ field }) => (
//                   <FormItem>
//                     <FormLabel>How Many Products?</FormLabel>
//                     <FormControl>
//                       <Input
//                         type="number"
//                         {...field}
//                         onChange={(e) => {
//                           field.onChange(parseInt(e.target.value));
//                         }}
//                       />
//                     </FormControl>
//                     <FormMessage />
//                   </FormItem>
//                 )}
//               />
//               <FormField
//                 control={form.control}
//                 name="lot"
//                 render={({ field }) => (
//                   <FormItem>
//                     <FormLabel>Lot Number</FormLabel>
//                     <FormControl>
//                       <Input {...field} />
//                     </FormControl>
//                     <FormMessage />
//                   </FormItem>
//                 )}
//               />
//             </div>
//             <div className="grid grid-cols-2 gap-4">
//               <FormField
//                 control={form.control}
//                 name="batchDateTime"
//                 render={({ field }) => (
//                   <FormItem>
//                     <FormLabel>Batch Date and Time</FormLabel>
//                     <FormControl>
//                       <Input type="datetime-local" {...field} />
//                     </FormControl>
//                     <FormDescription>
//                       Date and Time The Batch Was Tagged (Applied to Product)
//                     </FormDescription>
//                     <FormMessage />
//                   </FormItem>
//                 )}
//               />
//               <FormField
//                 control={form.control}
//                 name="producedAt"
//                 render={({ field }) => (
//                   <FormItem>
//                     <FormLabel>Production Date</FormLabel>
//                     <FormControl>
//                       <Input type="date" {...field} />
//                     </FormControl>
//                     <FormDescription>
//                       Date the product was created
//                     </FormDescription>
//                     <FormMessage />
//                   </FormItem>
//                 )}
//               />
//             </div>
//           </>
//         )}

//         {page === 2 && (
//           <>
//             <h2 className="text-2xl font-bold mb-6">Additional Metafields</h2>
//             {metafieldsQuery.data?.metafields.map((metafield, index) => (
//               <FormField
//                 control={form.control}
//                 key={metafield.id}
//                 name={`metafields.${index}.value`}
//                 render={({ field }) => (
//                   <FormItem>
//                     <FormLabel>{metafield.key}</FormLabel>
//                     <Controller
//                       control={form.control}
//                       name={`metafields.${index}.value`}
//                       render={({ field }) =>
//                         metafield.type === 'list' ? (
//                           <Select
//                             onValueChange={(value) => {
//                               field.onChange(value);
//                             }} // update react-hook-form with the selected value
//                             value={field.value} // bind the value from react-hook-form
//                           >
//                             <FormControl>
//                               <SelectTrigger>
//                                 <SelectValue placeholder="Select Option" />
//                               </SelectTrigger>
//                             </FormControl>
//                             <SelectContent>
//                               {metafield.listOptions?.map((option) => (
//                                 <SelectItem key={option} value={option}>
//                                   {option}
//                                 </SelectItem>
//                               ))}
//                             </SelectContent>
//                           </Select>
//                         ) : (
//                           <Input {...field} placeholder="Enter value" />
//                         )
//                       }
//                     />
//                     <FormMessage />
//                   </FormItem>
//                 )}
//               />
//             ))}
//             <FormField
//               control={form.control}
//               name="tags"
//               render={({ field }) => (
//                 <FormItem>
//                   <FormLabel>Internal Tags</FormLabel>
//                   <FormControl>
//                     <div className="flex flex-wrap items-center gap-2 p-2 border rounded-md focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
//                       {tags.map((tag, index) => (
//                         <Badge
//                           className="text-sm"
//                           key={index}
//                           variant="secondary"
//                         >
//                           {tag}
//                           <button
//                             className="ml-1 text-xs font-bold"
//                             onClick={() => {
//                               removeTag(tag);
//                             }}
//                             type="button"
//                           >
//                             ×
//                           </button>
//                         </Badge>
//                       ))}
//                       <Input
//                         {...field}
//                         className="flex-1 border-0 focus-visible:ring-0 focus-visible:ring-offset-0"
//                         onChange={handleTagInput}
//                         placeholder="Type a tag and press comma to add"
//                         value={form.watch('tags')}
//                       />
//                     </div>
//                   </FormControl>
//                   <FormDescription>
//                     Internal tags for organizing and grouping batch data for
//                     reporting and filtering. Type a tag and press comma to add.
//                   </FormDescription>
//                   <FormMessage />
//                 </FormItem>
//               )}
//             />
//           </>
//         )}

//         {page === 3 && (
//           <>
//             <h2 className="text-2xl font-bold mb-6">
//               InfoTap Label Information
//             </h2>
//             <p className="mb-4">
//               Please select the Tag Roll Serial Numbers used in this Batch.
//             </p>
//             <div className="space-y-2 ">
//               <Label>Available Rolls</Label>
//               <ScrollArea className="h-[300px] border rounded-md p-4">
//                 <div className="grid grid-cols-3 gap-2">
//                   <Controller
//                     control={form.control}
//                     name="rolls"
//                     render={({ field }) => (
//                       <>
//                         {availableRolls.map((roll) => {
//                           const isSelected = field.value.includes(roll.value);
//                           return (
//                             <Badge
//                               className={cn(
//                                 'text-sm cursor-pointer transition-all',
//                               )}
//                               key={roll.value}
//                               onClick={() => {
//                                 const newValue = isSelected
//                                   ? field.value.filter((v) => v !== roll.value)
//                                   : [...field.value, roll.value];
//                                 field.onChange(newValue);
//                               }}
//                               variant={isSelected ? 'secondary' : 'outline'}
//                             >
//                               {roll.label}
//                               {isSelected ? (
//                                 <X className="ml-2 h-4 w-4" />
//                               ) : (
//                                 <Plus className="ml-2 h-4 w-4" />
//                               )}
//                             </Badge>
//                           );
//                         })}
//                       </>
//                     )}
//                   />
//                 </div>
//               </ScrollArea>
//             </div>
//           </>
//         )}

//         {page === 4 && (
//           <>
//             <h2 className="text-2xl font-bold mb-6">Confirm Details</h2>
//             <Card>
//               <CardHeader>
//                 <CardTitle>Batch Details Summary</CardTitle>
//               </CardHeader>
//               <CardContent className="space-y-4">
//                 <div className="grid grid-cols-2 gap-4">
//                   <div>
//                     <h3 className="font-semibold">Product:</h3>
//                     <p>{productQuery.data?.product.name || 'Not specified'}</p>
//                   </div>
//                   <div>
//                     <h3 className="font-semibold">How Many Products:</h3>
//                     <p>
//                       {form.getValues('howManyProducts') || 'Not specified'}
//                     </p>
//                   </div>
//                   <div>
//                     <h3 className="font-semibold">Lot Number:</h3>
//                     <p>{form.getValues('lot') || 'Not specified'}</p>
//                   </div>
//                   <div>
//                     <h3 className="font-semibold">Batch Date and Time:</h3>
//                     <p>{form.getValues('batchDateTime') || 'Not specified'}</p>
//                   </div>
//                   <div>
//                     <h3 className="font-semibold">Production Date:</h3>
//                     <p>{form.getValues('producedAt') || 'Not specified'}</p>
//                   </div>
//                   {form.getValues('metafields').map((metafield, index) => (
//                     <div key={metafield.key}>
//                       <h3 className="font-semibold">
//                         {metafieldsQuery.data?.metafields[index].key}:
//                       </h3>
//                       <p>{metafield.value || 'Not specified'}</p>
//                     </div>
//                   ))}
//                   <div>
//                     <h3 className="font-semibold">Internal Tags:</h3>
//                     <p>{tags.join(', ') || 'None'}</p>
//                   </div>
//                   <div>
//                     <h3 className="font-semibold">Tag Roll Serial Numbers:</h3>
//                     <p>
//                       {form.getValues('rolls').join(', ') ||
//                         'None selected'}
//                     </p>
//                   </div>
//                 </div>
//               </CardContent>
//             </Card>
//             <p className="mt-4 text-sm text-muted-foreground">
//               Please review the information above. If you need to make any
//               changes, use the "Back" button to return to previous steps.
//             </p>
//           </>
//         )}

//         <div
//           className={cn(
//             'flex w-full justify-between',
//             page === 1 && 'justify-end',
//           )}
//         >
//           {page > 1 && (
//             <Button
//               onClick={() => {
//                 setPage(page - 1);
//               }}
//               type="button"
//               variant="outline"
//             >
//               Back
//             </Button>
//           )}
//           {page < 4 ? (
//             <Button
//               onClick={() => {
//                 setPage(page + 1);
//               }}
//               type="button"
//             >
//               Next
//             </Button>
//           ) : (
//             <Button type="submit">Submit</Button>
//           )}
//         </div>
//       </form>
//     </Form>
//   );
// }
