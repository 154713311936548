import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import type { Company } from '@/schemas/company';

import type { CreateBatchFormData } from './batch-form';

const CompanyFormSchema = z.object({
  id: z.string().optional(),
  name: z
    .string({ required_error: 'Company name is required' })
    .min(2, {
      message: 'Name must be at least 2 characters.',
    })
    .max(30, {
      message: 'Name must not be longer than 30 characters.',
    }),
  contactUrl: z
    .string({
      required_error: 'A contact URL is required.',
    })
    .url('URL must be valid'),
  policyUrl: z
    .string({
      required_error: 'A policy URL is required.',
    })
    .url('URL must be valid'),
  reportUrl: z
    .string({
      required_error: 'A report URL is required.',
    })
    .url('URL must be valid'),
  authAppBasePath: z
    .string({
      required_error: 'An App URL base path is required.',
    })
    .url('URL must be valid'),
});

export type CompanyFormData = z.infer<typeof CompanyFormSchema>;

export function CompanyForm({
  data,
  onCancel,
  onSubmit,
}: {
  data?: Company;
  onCancel?: () => void;
  onSubmit: (values: CompanyFormData) => void;
}) {
  const form = useForm<CompanyFormData>({
    resolver: zodResolver(CompanyFormSchema),
    values: data || {
      authAppBasePath: '',
      contactUrl: '',
      name: '',
      policyUrl: '',
      reportUrl: '',
    },
  });

  function submitHandler(data: CompanyFormData) {
    onSubmit(data);
  }

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(submitHandler)}>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input placeholder="Your name" {...field} />
                </FormControl>
                <FormDescription>
                  This is the name that will be displayed on your profile and in
                  emails.
                </FormDescription>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="contactUrl"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Contact URL</FormLabel>
              <FormControl>
                <Input
                  placeholder="https://www.yourdomain.com/contact"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                This is the Contact URL of your website.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="policyUrl"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Policy URL</FormLabel>
              <FormControl>
                <Input
                  placeholder="https://www.yourdomain.com/policy"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                This is the Policy URL of your website.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="reportUrl"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Report URL</FormLabel>
              <FormControl>
                <Input
                  placeholder="https://www.yourdomain.com/report"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                This is the Report URL of your website.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="authAppBasePath"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Auth App Base URL</FormLabel>
              <FormControl>
                <Input placeholder="https://www.yourdomain.com" {...field} />
              </FormControl>
              <FormDescription>
                This is the Base URL of your auth app, the target of your smart
                tag scans.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
          {onCancel ? (
            <Button onClick={onCancel} variant="outline">
              Cancel
            </Button>
          ) : null}

          <Button type="submit">Continue</Button>
        </div>
      </form>
    </Form>
  );
}
