import { useMutation } from '@tanstack/react-query';

import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';

import { useAppSelector } from './use-app-selector';

interface BulkUploadParams {
  brandId: string;
  orderId: string;
  formData: FormData;
}

async function bulkUpload({ brandId, orderId, formData }: BulkUploadParams) {
  await api.post(
    apiPaths.BULK_UPLOAD({ brandId, orderId }), // Add this endpoint to api-paths
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
  );
}

export function useBulkUploadMutation() {
  const selectedBrand = useAppSelector((state) => state.brands.selected);

  return useMutation({
    mutationFn: ({ formData, orderId }: Omit<BulkUploadParams, 'brandId'>) =>
      bulkUpload({
        brandId: selectedBrand?.id ?? '',
        orderId,
        formData,
      }),
    mutationKey: ['bulk-upload', selectedBrand?.id],
  });
}
