import { z } from 'zod';

import { formatDate } from '@/helpers/format-date';

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const NamespaceSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  createdAt: z.string().transform(formatDate),
  updatedAt: z.string().transform(formatDate),
});

export type Namespace = z.infer<typeof NamespaceSchema>;
