import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import { BrandSchema } from '@/schemas/brand';

const BrandListApiResponseSchema = createApiResponseSchema(
  z.array(BrandSchema),
);

async function getBrands() {
  const response = await api.get(apiPaths.BRANDS);
  const { data } = BrandListApiResponseSchema.parse(response.data);
  return { brands: data };
}

export function useBrands() {
  return useQuery({
    queryKey: ['brands'],
    queryFn: getBrands,
  });
}
