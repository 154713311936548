import { z } from 'zod';

export const BrandSchema = z.object({
  id: z.string(),
  name: z.string(),
  contactUrl: z.string(),
  policyUrl: z.string(),
  reportUrl: z.string(),
  logo: z.string().nullable(),
  logoUrl: z.string().nullable(),
  authAppBasePath: z.string(),
  companyId: z.string(),
});

export type Brand = z.infer<typeof BrandSchema>;
