import { usePasswordless } from 'amazon-cognito-passwordless-auth/react';
import * as React from 'react';

export enum ROLES {
  Admin = 'Admin',
  Customer = 'Customer',
}

type RoleTypes = keyof typeof ROLES;

// export const POLICIES = {
//   'comment:delete': (user: User, comment: Comment) => {
//     if (user.role === 'ADMIN') {
//       return true;
//     }

//     if (user.role === 'USER' && comment.author?.id === user.id) {
//       return true;
//     }

//     return false;
//   },
// };

export const useAuthorization = () => {
  const { tokensParsed } = usePasswordless();

  if (!tokensParsed) {
    throw Error('User does not exist!');
  }

  const userRoles = tokensParsed.accessToken['cognito:groups'] as unknown as
    | RoleTypes[]
    | undefined;

  const checkAccess = React.useCallback(
    ({ allowedRoles }: { allowedRoles: RoleTypes[] }) => {
      if (
        allowedRoles &&
        allowedRoles.length > 0 &&
        userRoles &&
        userRoles.length > 0
      ) {
        return userRoles.some((role) => allowedRoles.includes(role));
      }

      return true;
    },
    [userRoles],
  );

  return { checkAccess, roles: userRoles };
};

type AuthorizationProps = {
  forbiddenFallback?: React.ReactNode;
  children: React.ReactNode;
} & (
  | {
      allowedRoles: RoleTypes[];
      policyCheck?: never;
    }
  | {
      allowedRoles?: never;
      policyCheck: boolean;
    }
);

export function Authorization({
  policyCheck,
  allowedRoles,
  forbiddenFallback = null,
  children,
}: AuthorizationProps) {
  const { checkAccess } = useAuthorization();

  let canAccess = false;

  if (allowedRoles) {
    canAccess = checkAccess({ allowedRoles });
  }

  if (typeof policyCheck !== 'undefined') {
    canAccess = policyCheck;
  }

  return <>{canAccess ? children : forbiddenFallback}</>;
}
