interface SupportIFrameProps {
  brandId: string;
  accountName: string;
  crmCompanyId: string;
  email: string;
}

export function SupportIFrame({
  brandId,
  accountName,
  crmCompanyId,
  email,
}: SupportIFrameProps) {
  const src = `https://forms.zohopublic.com/forgestop/form/HelpDeskv1/formperma/0_BiEY2rNE5H9QiOl3snMhKxR3XqQp0pANw-C-Uu3xY?brandid=${brandId}&account_name=${accountName}&crm_company_id=${crmCompanyId}&email=${email}`;
  return (
    <iframe
      aria-label="Help Desk v1"
      className="h-full w-1/2 border-none"
      src={src}
      title="support-zoho"
    />
  );
}
