import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToast } from '@/components/ui/use-toast';
import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import { MetafieldSchema } from '@/schemas/metafield';
import type { Metafield } from '@/schemas/metafield';

import { useAppSelector } from './use-app-selector';

const MetafieldApiResponseSchema = createApiResponseSchema(MetafieldSchema);

async function updateMetafield({
  namespaceId,
  brandId,
  metafieldId,
  ...metafield
}: {
  brandId: string;
  namespaceId: string;
  metafieldId: string;
  isFavorite: boolean;
}) {
  const { data } = await api.put<Metafield>(
    apiPaths.METAFIELD_BY_ID({ brandId, namespaceId, metafieldId }),
    {
      ...metafield,
    },
  );

  return MetafieldApiResponseSchema.parse(data);
}

export function useUpdateMetafieldMutation() {
  const { toast } = useToast();
  const selectedBrand = useAppSelector((state) => state.brands.selected);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (metafield: {
      namespaceId: string;
      metafieldId: string;
      isFavorite: boolean;
    }) => updateMetafield({ ...metafield, brandId: selectedBrand?.id ?? '' }),
    mutationKey: ['update-metafield'],
    onSuccess: () => {
      toast({
        title: 'Metafield updated',
      });
    },
    onError: (error) => {
      toast({
        title: 'Error updating metafield',
        description: error.message,
        variant: 'destructive',
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['metafields', selectedBrand?.id],
      });
    },
  });
}
