import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CompanyFormData } from '@/components/forms/company-form';
import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import type { Company } from '@/schemas/company';
import { CompanySchema } from '@/schemas/company';

import { toast } from './use-toast';

const CompanyApiResponseSchema = createApiResponseSchema(CompanySchema);

async function createCompany(company: CompanyFormData) {
  const { data } = await api.post<Company>(apiPaths.COMPANIES, {
    ...company,
  });

  return CompanyApiResponseSchema.parse(data);
}

export function useCreateCompanyMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (company: CompanyFormData) => createCompany(company),
    mutationKey: ['create-company'],
    onSuccess: () => {
      toast({
        title: "Company created",
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['companies'],
      });
    },
  });
}
