import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { TagForm } from '@/components/forms/tag-form';
import { tagColumns } from '@/components/tag-columns';
import { DataTable } from '@/components/tag-table';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useBatchesNestedQuery } from '@/hooks/use-batches-nested-query';
import { useCreateTagMutation } from '@/hooks/use-create-tag-mutation';
import { useProductsQuery } from '@/hooks/use-products-query';
import { useTidsNestedQuery } from '@/hooks/use-tids-query';
import type { DialogType } from '@/store/dialog/dialog.slice';
import { selectDialog } from '@/store/dialog/dialog.slice';

interface DialogProps {
  header: string;
  description: string;
  component: JSX.Element;
}

export const createTagFormSchema = z.object({
  productId: z.string({
    required_error: 'Product is required.',
  }),
  batchId: z.string({
    required_error: 'Batch is required.',
  }),
  uid: z
    .string({ required_error: 'UID is required' })
    // .regex(/^([0-9a-fA-F]+)$/, {
    //   message: 'Invalid hexadecimal string',
    // })
    .min(14, {
      message: 'UID must be at least 14 characters.',
    })
    .max(14, {
      message: 'UID must not be longer than 14 characters.',
    }),
  sampleInfo: z
    .object({
      purpose: z.string().optional(),
      destination: z.string().optional(),
      testingCriteria: z.string().optional(),
    })
    .optional(),
});

export type CreateTagFormData = z.infer<typeof createTagFormSchema>;

export function Tags() {
  const form = useForm<CreateTagFormData>({
    resolver: zodResolver(createTagFormSchema),
  });
  const formSelectedProductId = form.watch('productId');
  const productsQuery = useProductsQuery();
  const batchesQuery = useBatchesNestedQuery({
    productId: formSelectedProductId,
  });
  const { data, isLoading } = useTidsNestedQuery({
    productId: form.watch('productId'),
    batchId: form.watch('batchId'),
  });
  const createTagMutation = useCreateTagMutation();
  const dispatch = useAppDispatch();
  const selectedDialog = useAppSelector((state) => state.dialog.selected);

  const createTagDialogHandler = () => {
    dispatch(selectDialog('create-tag'));
  };

  const closeDialogHandler = useCallback(() => {
    dispatch(selectDialog());
  }, [dispatch]);

  const createTagHandler = useCallback(
    (tag: CreateTagFormData) => {
      createTagMutation.mutate(tag);
      dispatch(selectDialog());
    },
    [createTagMutation, dispatch],
  );

  const tagDialogData: Record<Partial<DialogType>, DialogProps> = useMemo(
    () => ({
      'create-tag': {
        header: 'Create Tag',
        description: `Add a new tag`,
        component: (
          <TagForm
            batches={batchesQuery.data?.batches ?? []}
            form={form}
            onCancel={closeDialogHandler}
            onSubmit={createTagHandler}
            products={productsQuery.data?.products ?? []}
          />
        ),
      },
    }),
    [
      batchesQuery.data?.batches,
      closeDialogHandler,
      createTagHandler,
      form,
      productsQuery.data?.products,
    ],
  );

  return (
    <>
      <div className="flex-1 space-y-4 p-8 pt-6">
        <div className="flex items-center justify-between space-y-2">
          <div>
            <h2 className="text-3xl font-bold tracking-tight">Tags</h2>
          </div>
          <div className="flex items-center space-x-2">
            <Button onClick={createTagDialogHandler}>Create new tag</Button>
          </div>
        </div>
        <DataTable
          columns={tagColumns}
          data={data?.tags ?? []}
          isLoading={isLoading}
        />
      </div>
      <Dialog
        onOpenChange={closeDialogHandler}
        open={selectedDialog !== undefined}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {selectedDialog ? tagDialogData[selectedDialog].header : null}
            </DialogTitle>
            <DialogDescription>
              {selectedDialog
                ? tagDialogData[selectedDialog].description
                : null}
            </DialogDescription>
          </DialogHeader>
          {selectedDialog ? tagDialogData[selectedDialog].component : null}
        </DialogContent>
      </Dialog>
    </>
  );
}
