import { useEffect, useState } from 'react';

import { useScanQuery } from '@/hooks/use-scan-query';
import { useSearchFilters } from '@/hooks/use-search-filters';
import { scanColumns } from '@/modules/scans/components/data-table/scan-columns';
import { DataTable } from '@/modules/scans/components/data-table/scan-table';

import type {
  ColumnFiltersState,
  PaginationState,
} from '@tanstack/react-table';

export function Scans() {
  const filters = useSearchFilters();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  useEffect(() => {
    setColumnFilters(filters);
  }, [filters]);

  const { data, isLoading } = useScanQuery({
    pagination: {
      take: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
    },
    filters: columnFilters,
  });

  return (
    <DataTable
      columnFilters={columnFilters}
      columns={scanColumns}
      data={data?.scans ?? []}
      initialColumnVisibility={{ tagTamper: false }}
      isLoading={isLoading}
      onColumnFiltersChange={setColumnFilters}
      onPaginationChange={setPagination}
      pagination={pagination}
      rowCount={data?.pagination?.total ?? 0}
    />
  );
}
