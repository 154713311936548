import {
  CaretSortIcon,
  CheckIcon,
  PlusCircledIcon,
} from '@radix-ui/react-icons';
import { usePasswordless } from 'amazon-cognito-passwordless-auth/react';
import * as React from 'react';

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  PopoverTrigger,
  Popover,
  PopoverContent,
} from '@/components/ui/popover';
import { useAppDispatch } from '@/hooks/use-app-dispatch';
import { useAppSelector } from '@/hooks/use-app-selector';
import { useBrands } from '@/hooks/use-brands';
import { useCreateCompanyMutation } from '@/hooks/use-create-company-mutation';
import { Authorization } from '@/lib/authorization';
import { cn } from '@/lib/utils';
import type { Brand } from '@/schemas/brand';
import { selectBrand } from '@/store/brands/brands.slice';

import { CompanyForm } from './forms/company-form';

import type { CompanyFormData } from './forms/company-form';

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

type BrandSwitcherProps = PopoverTriggerProps;

export function BrandSwitcher({ className }: BrandSwitcherProps) {
  const dispatch = useAppDispatch();
  const selectedBrand = useAppSelector((state) => state.brands.selected);
  const { tokensParsed } = usePasswordless();
  const tenantId = tokensParsed?.idToken['custom:tenant_id'];
  const { data } = useBrands();
  const createBrandMutation = useCreateCompanyMutation();
  const [open, setOpen] = React.useState(false);
  const [showNewBrandDialog, setShowNewBrandDialog] = React.useState(false);

  const groups = React.useMemo(
    () => [
      {
        label: 'Brands',
        brands: data?.brands,
      },
    ],
    [data],
  );

  const createBrandHandler = (brand: CompanyFormData) => {
    createBrandMutation.mutate(brand);
    setShowNewBrandDialog(false);
  };

  const selectBrandHandler = React.useCallback(
    (brand: Brand) => {
      dispatch(selectBrand(brand));
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (!selectedBrand) {
      const firstBrand = groups[0].brands?.[0];

      if (data?.brands.length && firstBrand) {
        selectBrandHandler(firstBrand);
      }
    }
  }, [data?.brands.length, groups, selectBrandHandler, selectedBrand]);

  React.useEffect(() => {
    if (!selectedBrand) {
      const tenant = groups[0].brands?.find((brand) => brand.id === tenantId);

      if (data?.brands.length && tenant) {
        selectBrandHandler(tenant);
      }
    }
  }, [
    data?.brands.length,
    groups,
    selectBrandHandler,
    selectedBrand,
    tenantId,
  ]);

  if (!data?.brands) {
    return null;
  }

  return (
    <Dialog onOpenChange={setShowNewBrandDialog} open={showNewBrandDialog}>
      <Popover onOpenChange={setOpen} open={open}>
        <PopoverTrigger asChild>
          <Button
            aria-expanded={open}
            aria-label="Select a company"
            className={cn('w-[200px] justify-between', className)}
            role="combobox"
            variant="outline"
          >
            <Avatar className="mr-2 h-5 w-5">
              <AvatarImage
                alt={selectedBrand?.name}
                src={selectedBrand?.logoUrl ?? undefined}
              />
              <AvatarFallback>SC</AvatarFallback>
            </Avatar>
            {selectedBrand?.name}
            <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandList>
              <CommandInput placeholder="Search brand..." />
              <CommandEmpty>No brand found.</CommandEmpty>
              {groups.map((group) => (
                <CommandGroup heading={group.label} key={group.label}>
                  {group.brands?.map((brand) => (
                    <CommandItem
                      className="text-sm"
                      key={brand.id}
                      onSelect={() => {
                        selectBrandHandler(brand);
                        setOpen(false);
                      }}
                    >
                      <Avatar className="mr-2 h-5 w-5">
                        <AvatarImage
                          alt={brand.name}
                          className="grayscale"
                          src={brand.logoUrl ?? undefined}
                        />
                        <AvatarFallback>CCL</AvatarFallback>
                      </Avatar>
                      {brand.name}
                      <CheckIcon
                        className={cn(
                          'ml-auto h-4 w-4',
                          selectedBrand?.id === brand.id
                            ? 'opacity-100'
                            : 'opacity-0',
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              ))}
            </CommandList>
            <Authorization allowedRoles={['Admin']}>
              <CommandSeparator />
              <CommandList>
                <CommandGroup>
                  <DialogTrigger asChild>
                    <CommandItem
                      onSelect={() => {
                        setOpen(false);
                        setShowNewBrandDialog(true);
                      }}
                    >
                      <PlusCircledIcon className="mr-2 h-5 w-5" />
                      Create Brand
                    </CommandItem>
                  </DialogTrigger>
                </CommandGroup>
              </CommandList>
            </Authorization>
          </Command>
        </PopoverContent>
      </Popover>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create company</DialogTitle>
          <DialogDescription>
            Add a new company to manage products and customers.
          </DialogDescription>
        </DialogHeader>
        <div>
          <CompanyForm
            onCancel={() => {
              setShowNewBrandDialog(false);
            }}
            onSubmit={createBrandHandler}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
