import { useState } from 'react';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useCreateMetafieldMutation } from '@/hooks/use-create-metafield-mutation';
import { useMetafieldsQuery } from '@/hooks/use-metafields-query';
import { useNamespacesQuery } from '@/hooks/use-namespaces-query';
import { useUpdateMetafieldMutation } from '@/hooks/use-update-metafield-mutation';
import type { MetafieldRequestParams } from '@/schemas/metafield';

import { MetafieldForm } from './metafield-form';
import { MetafieldsList } from './metafields-list';
import { parseListOptions } from './metafields.helper';

import type { MetafieldFormData } from './metafield-form';

export function Metafields() {
  const [isOpen, setIsOpen] = useState(false);
  const namespacesQuery = useNamespacesQuery();
  // TODO: Use Dynamic namespace id based on the route params
  const namespaceId = namespacesQuery.data?.namespaces[0].id || '';
  const metafieldsQuery = useMetafieldsQuery(namespaceId);
  const createMetafieldMutation = useCreateMetafieldMutation();
  const updateMetafieldMutation = useUpdateMetafieldMutation();

  const onSubmit = async (metafield: MetafieldFormData) => {
    const listOptions =
      metafield.type === 'list' && metafield.listOptions
        ? parseListOptions(metafield.listOptions)
        : null;

    const processedData: MetafieldRequestParams = {
      key: metafield.key,
      type: metafield.type,
      isFavorite: metafield.isFavorite,
      listOptions,
    };

    // if (metafield.type === 'list' && metafield.listOptions) {
    //   processedData.listOptions = parseListOptions(metafield.listOptions);
    // }
    await createMetafieldMutation.mutateAsync({
      ...processedData,
      namespaceId: metafield.namespaceId,
    });
    setIsOpen(false);
  };

  const onUpdate = (data: { metafieldId: string; isFavorite: boolean }) => {
    updateMetafieldMutation.mutate({ ...data, namespaceId });
  };

  const closeDialogHandler = () => {
    setIsOpen(false);
  };

  const initialValues: MetafieldFormData = {
    namespaceId: namespacesQuery.data?.namespaces[0].id || '',
    key: '',
    type: 'string',
    isFavorite: false,
    listOptions: '',
  };

  return (
    <>
      <div className="flex-1 space-y-4 p-8 pt-6">
        <div className="flex items-center justify-between space-y-2">
          <div>
            <h2 className="text-3xl font-bold tracking-tight">Metafields</h2>
          </div>
          <div className="flex items-center space-x-2">
            <Button
              onClick={() => {
                setIsOpen(true);
              }}
            >
              Create New Metafield
            </Button>
          </div>
        </div>
        <MetafieldsList
          isLoading={metafieldsQuery.isLoading}
          metafields={metafieldsQuery.data?.metafields}
          onToggleFavorite={onUpdate}
        />
      </div>
      <Dialog onOpenChange={closeDialogHandler} open={isOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create Metafield</DialogTitle>
            <DialogDescription>
              Add a new metafield to be used on your batches.
            </DialogDescription>
          </DialogHeader>
          <MetafieldForm onSubmit={onSubmit} values={initialValues} />
        </DialogContent>
      </Dialog>
    </>
  );
}
