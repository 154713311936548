import { useOrdersQuery } from '@/hooks/use-orders-query';

import { columns } from './components/data-table/columns';
import { DataTable } from './components/data-table/data-table';

export function Orders() {
  const { data } = useOrdersQuery();
  // const { isLoading, pageIndex, pageSize } = useOrdersQuery();
  return (
    <DataTable
      columns={columns}
      data={data?.orders ?? []}
      // isLoading={isLoading}
      // pageIndex={pageIndex}
      // pageSize={pageSize}
    />
  );
}
