import { EyeIcon } from 'lucide-react';
import { Link } from 'react-router-dom';

import { DataTableColumnHeader } from '@/components/data-table-column-header';
import { ShortenedUUID } from '@/components/shortened-uuid';
import type { Scan } from '@/schemas/scan-schema';

import { statuses } from '../../data/scan-data';

import type { ColumnDef } from '@tanstack/react-table';

export const scanColumns: ColumnDef<Scan>[] = [
  {
    accessorKey: 'createdAt',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Scanned at" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue('createdAt')}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: '_id',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Id" />
    ),
    cell: ({ row }) => {
      return <ShortenedUUID uuid={row.getValue('_id')} />;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: 'productName',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Product" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue('productName')}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'countryName',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Country" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue('countryName')}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'regionName',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Region" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue('regionName')}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'city',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="City" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue('city')}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'status',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      const status = statuses.find(
        (item) => item.value === row.getValue('status'),
      );

      if (!status) {
        return null;
      }

      return (
        <div className="flex w-[100px] items-center">
          <status.icon
            className={`${status.className} mr-2 h-4 w-4 text-muted-foreground`}
          />
          <span>{status.label}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: 'tagTamper',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Seal" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue('tagTamper') ? 'Open' : 'Closed'}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'view',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="View Detail" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <Link to={row.original.tidId ?? ''}>
            <EyeIcon className="h-4 w-4" />
          </Link>
        </div>
      );
    },
  },
];
