import { useQueryClient } from '@tanstack/react-query';
import { RefreshCwIcon } from 'lucide-react';
import { Link, Outlet, useParams } from 'react-router-dom';

import { CalendarDateRangePicker } from '@/components/date-range-picker';
import { Indicators } from '@/components/indicators';
import { Button } from '@/components/ui/button';

export function ScansLayout() {
  const { tidId } = useParams();
  const queryClient = useQueryClient();
  const refetchData = () => {
    void queryClient.invalidateQueries({ queryKey: ['scans'] });
  };

  const breadcrumb = (
    <>
      <Link to="/admin/scans">Scans</Link> {`/ ${tidId?.slice(0, 8)}...`}
    </>
  );
  return (
    <div className="flex-1 space-y-4 p-8 pt-6">
      <div className="flex items-center justify-between space-y-2">
        <h2 className="text-3xl font-bold tracking-tight">
          {tidId ? breadcrumb : 'Scans'}
        </h2>
        <div className="flex items-center space-x-2">
          {/* <CalendarDateRangePicker />
          <Button>Download</Button> */}
          <Button
            className="flex gap-2"
            onClick={refetchData}
            variant="outline"
          >
            <RefreshCwIcon size="1rem" />
            Refresh Data
          </Button>
        </div>
      </div>
      <Indicators />
      <Outlet />
    </div>
  );
}
