import { CopyIcon } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';

interface ShortenedUUIDProps {
  uuid: string;
  copyHidden?: boolean;
}

export function ShortenedUUID({
  uuid,
  copyHidden = false,
}: ShortenedUUIDProps) {
  const { toast } = useToast();
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(uuid);
      toast({
        title: 'UUID copied to clipboard!',
      });
    } catch (err) {
      toast({
        title: 'Failed to copy UUID:',
        description: (err as Error).message,
      });
    }
  };

  return (
    <div className="flex space-x-2 items-center">
      <span>{uuid.slice(0, 8)}</span>
      {!copyHidden && (
        <Button onClick={() => void handleCopy()} size="icon" variant="ghost">
          <CopyIcon size="1rem" />
        </Button>
      )}
    </div>
  );
}
