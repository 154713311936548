import { zodResolver } from '@hookform/resolvers/zod';
import { addWeeks, parse, isValid, isBefore } from 'date-fns';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { ButtonLoading } from '@/components/button-loading';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/use-toast';

const orderFormSchema = z.object({
  SingleLine2: z.string().optional(),
  SingleLine: z.string().optional(),
  Email: z.string().email('Invalid email address').optional(),
  Number: z.string().min(1, 'Amount is required'),
  SingleLine1: z.string().refine(
    (date) => {
      // Parse the input date
      const parsedDate = parse(date, 'yyyy-MM-dd', new Date());

      // Return false if date is invalid
      if (!isValid(parsedDate)) {
        return false;
      }

      // Calculate minimum allowed date (3 weeks from today)
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset time part
      const minDate = addWeeks(today, 3);

      // Compare dates (true if parsedDate is >= minDate)
      return !isBefore(parsedDate, minDate);
    },
    () => ({
      message: `Date must be at least 3 weeks from today (${addWeeks(
        new Date(),
        3,
      ).toLocaleDateString('en-CA', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      })})`,
    }),
  ),
  MultiLine: z.string().optional(),
  zf_referrer_name: z.string().optional(),
  zf_redirect_url: z.string().optional(),
  zc_gad: z.string().optional(),
});

type OrderFormData = z.infer<typeof orderFormSchema>;

interface CreateOrderFormProps {
  onSubmit: () => void;
  defaultValues?: Partial<OrderFormData>;
}

export function CreateOrderForm({
  onSubmit,
  defaultValues,
}: CreateOrderFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<OrderFormData>({
    resolver: zodResolver(orderFormSchema),
    mode: 'onTouched', // Enable real-time validation
    defaultValues: {
      ...defaultValues,
      Number: '',
      SingleLine1: '',
      MultiLine: '',
      zf_referrer_name: '',
      zf_redirect_url: '',
      zc_gad: '',
    },
  });

  const submitHandler = async (data: OrderFormData) => {
    setIsSubmitting(true);

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value || '');
    });

    try {
      await fetch(
        'https://forms.zohopublic.com/forgestop/form/OrderForm/formperma/FGlOLZm7Fx5m8WXOC_ph8-tNvVmrOjh5tSkrlP4xOvM/htmlRecords/submit',
        {
          method: 'POST',
          body: formData,
          mode: 'no-cors',
          headers: {
            Accept: 'application/json',
          },
        },
      );

      toast({
        title: 'Success!',
        description: 'Your order has been submitted successfully.',
      });

      form.reset();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was a problem submitting your order.',
        variant: 'destructive',
      });
    } finally {
      setIsSubmitting(false);
      onSubmit();
    }
  };

  return (
    <Form {...form}>
      <form
        className="space-y-8"
        encType="multipart/form-data"
        onSubmit={form.handleSubmit(submitHandler)}
      >
        <input type="hidden" {...form.register('zf_referrer_name')} />
        <input type="hidden" {...form.register('zf_redirect_url')} />
        <input type="hidden" {...form.register('zc_gad')} />

        <FormField
          control={form.control}
          name="Number"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Amount <em>*</em>
              </FormLabel>
              <FormControl>
                <Input type="number" {...field} maxLength={18} />
              </FormControl>
              <FormDescription>
                How Many InfoTap (Physical Encoded Chips) do you need?
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="SingleLine1"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Date Required <em>*</em>
              </FormLabel>
              <FormControl>
                <Input type="date" {...field} />
              </FormControl>
              <FormDescription>
                When do you need the InfoTap in Stock? Please remember there's
                3-4 weeks turnaround.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="MultiLine"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Comments</FormLabel>
              <FormControl>
                <Textarea {...field} maxLength={65535} />
              </FormControl>
              <FormDescription>
                Any comments or special instructions?
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-end">
          {isSubmitting ? (
            <ButtonLoading />
          ) : (
            <Button type="submit">Submit</Button>
          )}
        </div>
      </form>
    </Form>
  );
}
