import { useDropzone } from 'react-dropzone';

import fallbackImage from '@/assets/fallback-image.png';
import { cn } from '@/lib/utils';

import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label } from '../ui/label';

export function FileUploadForm({
  onDrop,
  filename,
  url,
  label,
  showPreview = true,
}: {
  onDrop: (acceptedFiles: File[]) => Promise<void> | void;
  filename?: string;
  url?: string;
  label?: string;
  showPreview?: boolean;
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const thumbnail = url ?? fallbackImage;

  return (
    <div className="flex flex-col items-start space-y-2">
      {label ? <Label>File</Label> : null}
      <div className="flex items-center gap-6 w-full" {...getRootProps()}>
        {showPreview ? (
          <div className="flex flex-col">
            <img
              alt="Uploaded file"
              className={cn(
                'w-28 h-28 rounded-full border-dashed border-2 p-1 space-x-4 ',
                isDragActive ? 'border-blue-400' : null,
              )}
              height="200"
              src={thumbnail}
              style={{
                aspectRatio: '200/200',
                objectFit: 'cover',
              }}
              width="200"
            />
          </div>
        ) : null}

        <div className="flex flex-col justify-between h-full flex-1 gap-2">
          <div className="text-gray-500 dark:text-gray-400 ">
            Filename: {filename}
          </div>
          <Button className="px-4 py-2" variant="outline">
            <Input id="logo" name="logo" type="file" {...getInputProps()} />
            <label className="cursor-pointer" htmlFor="upload">
              {`${filename ? 'Replace' : 'Select'} File`}
            </label>
          </Button>
        </div>
      </div>
    </div>
  );
}
