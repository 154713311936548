import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { usePasswordless } from 'amazon-cognito-passwordless-auth/react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { ThemeProvider } from '@/components/theme-provider';
import './globals.css';
import './styles.css';
import { api } from '@/lib/api';
import { store } from '@/store';

import { router } from './router';

const queryClient = new QueryClient();

function App(): JSX.Element {
  const { tokens } = usePasswordless();

  if (!tokens?.accessToken) return <div>Loading...</div>;

  api.defaults.headers.common.Authorization = `Bearer ${tokens.accessToken}`;
  api.defaults.headers.common['X-ID-TOKEN'] = tokens.idToken;

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
          <RouterProvider router={router} />
        </ThemeProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
