import { usePasswordless } from 'amazon-cognito-passwordless-auth/react';
import { Navigate } from 'react-router-dom';

export function Root() {
  const { signInStatus } = usePasswordless();

  if (signInStatus === 'SIGNED_IN') {
    return <Navigate to="admin" />;
  }

  return null;
}
