import { z } from 'zod';

import { formatDate } from '@/helpers/format-date';
import { BatchMetafieldSchema } from '@/hooks/use-batch-query';

const OrderSchema = z.object({
  id: z.string().uuid(),
  status: z.enum([
    'Pending',
    'Processing',
    'Ready',
    'Shipped',
    'Delivered',
    'Completed',
    'Cancelled',
    'On Hold',
    'Failed',
    'Returned',
    'Refunded',
  ]),
  code: z.string(),
  chipModel: z.string(),
  brandId: z.string().uuid(),
  providerId: z.string().uuid().nullable(),
  createdAt: z.string().transform(formatDate).nullable(),
  updatedAt: z.string().transform(formatDate).nullable(),
});

const ProductSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  brandId: z.string().uuid(),
  sku: z.string(),
  image: z.string(),
  createdAt: z.string().transform(formatDate).nullable(),
  updatedAt: z.string().transform(formatDate).nullable(),
});

const BatchSchema = z.object({
  id: z.string().uuid(),
  lot: z.string(),
  expiresAt: z.string().nullable(),
  producedAt: z.string().transform(formatDate).nullable(),
  tags: z.array(z.string()),
  brandId: z.string().uuid(),
  productId: z.string().uuid(),
  product: ProductSchema,
  batchMetafields: z.array(BatchMetafieldSchema),
  createdAt: z.string().transform(formatDate).nullable(),
  updatedAt: z.string().transform(formatDate).nullable(),
});

export const TidSchema = z.object({
  id: z.string().uuid(),
  roll: z.string().nullable(),
  orderId: z.string().uuid(),
  order: OrderSchema,
  batchId: z.string().uuid(),
  batch: BatchSchema,
  isActive: z.boolean(),
  createdAt: z.string().transform(formatDate).nullable(),
  scannedAt: z.string().nullable(),
  updatedAt: z.string().transform(formatDate).nullable(),
  sampleInfo: z
    .object({
      purpose: z.string().optional(),
      destination: z.string().optional(),
      testingCriteria: z.string().optional(),
    })
    .nullable(),
});

export type Tid = z.infer<typeof TidSchema>;
