// import { zodResolver } from '@hookform/resolvers/zod';
// import { useForm } from 'react-hook-form';
// import * as z from 'zod';

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import type { CreateTagFormData } from '@/pages/dashboard/tags';
import type { Batch } from '@/schemas/batch-nested';
import type { Product } from '@/schemas/product';

import { Button } from '../ui/button';
// import {
//   Select,
//   SelectContent,
//   SelectItem,
//   SelectTrigger,
//   SelectValue,
// } from '../ui/select';

import type { UseFormReturn } from 'react-hook-form';

export function TagForm({
  onCancel,
  onSubmit,
  // batches,
  // products,
  form,
}: {
  onCancel: () => void;
  onSubmit: (product: CreateTagFormData) => void;
  batches?: Batch[];
  products?: Product[];
  form: UseFormReturn<CreateTagFormData>;
}) {
  const submitHandler = (tag: CreateTagFormData) => {
    onSubmit(tag);
  };

  console.log(form.getValues());

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(submitHandler)}>
        {/* <FormField
          control={form.control}
          name="productId"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Product</FormLabel>
                <Select {...field}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a batch" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {products.map((product) => (
                      <SelectItem key={product.id} value={product.id}>
                        {product.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormDescription>
                  You select the batch related to the product and tag
                </FormDescription>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="batchId"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Batch</FormLabel>
                <Select {...field}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a batch" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {batches.map((batch) => (
                      <SelectItem key={batch.id} value={batch.id}>
                        {batch.lot}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormDescription>
                  You select the batch related to the product and tag
                </FormDescription>
                <FormMessage />
              </FormItem>
            );
          }}
        /> */}
        <FormField
          control={form.control}
          name="uid"
          render={({ field }) => (
            <FormItem>
              <FormLabel>UID</FormLabel>
              <FormControl>
                <Input maxLength={14} placeholder="66F113EE8B9521" {...field} />
              </FormControl>
              <FormDescription>This is the Tag identifier.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="sampleInfo.purpose"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Purpose</FormLabel>
              <FormControl>
                <Input placeholder="Quality control" {...field} />
              </FormControl>
              <FormDescription>This is the Tag identifier.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="sampleInfo.destination"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Destination</FormLabel>
              <FormControl>
                <Input placeholder="R&D Department" {...field} />
              </FormControl>
              <FormDescription>This is the Tag identifier.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="sampleInfo.testingCriteria"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Testing Criteria</FormLabel>
              <FormControl>
                <Input placeholder="Durability Test" {...field} />
              </FormControl>
              <FormDescription>This is the Tag identifier.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
          <Button onClick={onCancel} variant="outline">
            Cancel
          </Button>
          <Button type="submit">Continue</Button>
        </div>
      </form>
    </Form>
  );
}
