import { z } from 'zod';

export const IndicatorsSchema = z.object({
  totalTids: z.number(),
  totalScans: z.number(),
  invalid: z.number(),
  rescan: z.number(),
  authenticated: z.number(),
  counterfeit: z.number(),
});
