import fallBackImage from '@/assets/fallback-image.png';
import { DataTableColumnHeader } from '@/components/data-table-column-header';
import { cn } from '@/lib/utils';
import type { Product } from '@/schemas/product';

import { DataTableRowActions } from './product-data-table-row-actions';

import type { ColumnDef } from '@tanstack/react-table';

export const columns: ColumnDef<Product>[] = [
  {
    accessorKey: 'imageUrl',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Image" />
    ),
    enableSorting: false,
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <img
            alt="Uploaded file"
            className={cn('w-12 h-12 rounded-full')}
            src={row.getValue('imageUrl') ?? fallBackImage}
            style={{
              aspectRatio: '200/200',
              objectFit: 'cover',
            }}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue('name')}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'sku',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="SKU" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="font-medium">{row.getValue('sku')}</span>
        </div>
      );
    },
  },
  {
    accessorKey: 'batchCount',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Batches" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="font-medium">{row.getValue('batchCount')}</span>
        </div>
      );
    },
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      return (
        <div className="flex">
          <DataTableRowActions row={row} />
        </div>
      );
    },
  },
];
