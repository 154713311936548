import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useSearchFilters() {
  const [searchParams] = useSearchParams();

  const filters = useMemo(() => {
    if (!searchParams.has('status')) {
      return [];
    }

    const [first] = searchParams.entries();
    const [id, value] = first;

    return [{ id, value }];
  }, [searchParams]);

  return filters;
}
