import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CreateProductFormData } from '@/components/forms/product-form';
import { toast } from '@/components/ui/use-toast';
import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import type { Product } from '@/schemas/product';
import { ProductSchema } from '@/schemas/product';

import { useAppSelector } from './use-app-selector';

const ProductApiResponseSchema = createApiResponseSchema(ProductSchema);

async function createProduct({ brandId, ...product }: CreateProductFormData) {
  const { data } = await api.post<Product>(
    apiPaths.PRODUCTS({ brandId: brandId ?? '' }),
    {
      ...product,
    },
  );

  return ProductApiResponseSchema.parse(data);
}

export function useCreateProductMutation() {
  const selectedBrand = useAppSelector((state) => state.brands.selected);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (product: CreateProductFormData) =>
      createProduct({ ...product, brandId: selectedBrand?.id ?? '' }),
    mutationKey: ['create-product'],
    onSuccess: () => {
      toast({
        title: 'Product created',
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['products', selectedBrand?.id],
      });
    },
  });
}
