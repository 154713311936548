import type { AxiosError } from 'axios';
import type { ZodError } from 'zod';

export function getZodErrorMessages(error: AxiosError<ZodError>): string[] {
  if (error.response?.data.issues.length) {
    return error.response.data.issues.map((issue) => issue.message);
  }

  return [];
}
