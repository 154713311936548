import { Outlet } from 'react-router-dom';

import logoImg from '@/assets/logo.png';
import { BrandSwitcher } from '@/components/brand-switcher';
import { MainNav } from '@/components/main-nav';
import { ModeToggle } from '@/components/mode-toggle';
import { Toaster } from '@/components/ui/toaster';
import { UserNav } from '@/components/user-nav';

const navItems = [
  { title: 'Dashboard', href: 'dashboard' },
  { title: 'Scans', href: 'scans' },
  { title: 'Products', href: 'products' },
  { title: 'Orders', href: 'orders' },
  { title: 'Metafields', href: 'metafields' },
];

export function DashboardLayout() {
  return (
    <>
      <div className="md:hidden">
        <img
          alt="Dashboard"
          className="block dark:hidden"
          height={866}
          src="/examples/dashboard-light.png"
          width={1280}
        />
        <img
          alt="Dashboard"
          className="hidden dark:block"
          height={866}
          src="/examples/dashboard-dark.png"
          width={1280}
        />
      </div>
      <div className="hidden flex-col md:flex h-full">
        <div className="border-b">
          <div className="flex h-16 items-center px-4 gap-6">
            <div className="mr-auto place-self-center flex items-center space-x-4">
              <img alt="Forgestop Logo" src={logoImg} width={128} />
            </div>
            <div className="absolute right-1/2 translate-x-1/2">
              <MainNav className="place-self-center" items={navItems} />
            </div>
            <div className="ml-auto place-self-center flex items-center space-x-4">
              {/* <ModeToggle /> */}
              <BrandSwitcher />
              <UserNav />
            </div>
          </div>
        </div>
        <Outlet />
      </div>
      <Toaster />
    </>
  );
}
