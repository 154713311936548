import { useQuery } from '@tanstack/react-query';

import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import { ProductSchema } from '@/schemas/product';

const ProductApiResponseSchema = createApiResponseSchema(ProductSchema);

interface GetProductParams {
  brandId: string;
  productId: string;
}

async function getProduct({ brandId, productId }: GetProductParams) {
  const response = await api.get(
    apiPaths.PRODUCT_BY_ID({ brandId, productId }),
  );

  const { data } = ProductApiResponseSchema.parse(response.data);

  return { product: data };
}

const productQuery = ({ productId, brandId }: GetProductParams) => ({
  queryKey: ['product', productId],
  queryFn: () =>
    getProduct({
      brandId,
      productId,
    }),
  enabled: Boolean(brandId) && Boolean(productId),
});

export function useProductQuery({ productId, brandId }: GetProductParams) {
  return useQuery(productQuery({ productId, brandId }));
}
