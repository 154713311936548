import { usePasswordless } from 'amazon-cognito-passwordless-auth/react';
import { Navigate, Outlet } from 'react-router-dom';

import type { ProtectedRouteProps } from './protected-route.types';

export function ProtectedRoute({
  redirectPath = '/',
  children,
}: ProtectedRouteProps) {
  const { signInStatus } = usePasswordless();

  if (signInStatus !== 'SIGNED_IN') {
    return <Navigate replace to={redirectPath} />;
  }

  if (children) {
    return children;
  }

  return <Outlet />;
}
