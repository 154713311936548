import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToast } from '@/components/ui/use-toast';
import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';
import { ProductSchema } from '@/schemas/product';

import { useAppSelector } from './use-app-selector';

const ProductApiResponseSchema = createApiResponseSchema(ProductSchema);

interface UpdateProductImageParams {
  brandId: string;
  productId: string;
  formData: FormData;
}

async function updateProductImage({
  brandId,
  formData,
  productId,
}: UpdateProductImageParams) {
  const response = await api.patch(
    apiPaths.PRODUCT_IMAGE_BY_ID({ brandId, productId }),
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
  );

  return ProductApiResponseSchema.parse(response.data);
}

export function useUpdateProductImageMutation() {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const selectedBrand = useAppSelector((state) => state.brands.selected);
  const selectedProduct = useAppSelector((state) => state.products.selected);

  return useMutation({
    mutationFn: (formData: FormData) =>
      updateProductImage({
        brandId: selectedBrand?.id ?? '',
        productId: selectedProduct?.id ?? '',
        formData,
      }),
    mutationKey: [
      'update-product-image',
      selectedBrand?.id,
      selectedProduct?.id,
    ],
    onSuccess: () => {
      toast({
        title: 'Product image updated',
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['products', selectedBrand?.id],
      });
      await queryClient.invalidateQueries({
        queryKey: ['product', selectedProduct?.id],
      });
    },
  });
}
